import { SelectData, SelectProps, SelectDataOption } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import { GetOrder } from '../../models/Order';
import stringUtils from '../../utils/stringUtils';

class SearchOR {
  search: string | undefined;
  customerId: string | undefined;
}

export interface SelectOrderOption extends SelectDataOption {
  data: GetOrder;
}

export const SelectOrder = ({
  setValue,
  register,
  registerName = 'order',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = true,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectOrderOption[]>([]);
  const [filterText, setFilterText] = useState('');

  const { t } = useTranslation();

  const fetchData = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/order/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}`;

      const dataToSend: SearchOR = {
        search: filterText,
        customerId: (searchParent ?? null) === null ? undefined : searchParent,
      };

      axios
        .post(Config.get_API_EXTRANET_URL(url), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setFetchedOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    } else {
      setFetchedOptions([]);
    }
  };

  const setFetchedOptions = (fetchedOptions: GetOrder[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: s.customerOrderNumber + (s.externalReference ? ' - ' + s.externalReference : ''),
          searchParentRef: s.customerId,
          data: s,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  return (
    <SelectData
      title={t('common.order')}
      placeholder={t('common.orderChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption}
      filterText={filterText}
      fetch={fetchData}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};
