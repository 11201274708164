import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralCarrier from './GeneralCarrier';
import SubMenuCarrier from './SubMenuCarrier';
import { GetCarrier } from '../../../models/Carrier';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListTrips from '../../lists/Trips/ListTrips';
import ListOrders from '../../lists/Orders/ListOrders';
import ListContacts from '../../lists/Contacts/ListContacts';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import ListBuildingSites from '../../lists/BuildingSites/ListBuildingSites';
import ListVehicles from '../../lists/Vehicles/ListVehicles';

interface DetailCarrierProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailCarrier = React.memo((props: DetailCarrierProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast, addFixedToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
  };

  const { id, mode, autosign } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getCarrier, setGetCarrier] = useState<GetCarrier>();

  function getCurrentCarrier(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/carrier/detail/';
      const url = Config.get_API_EXTRANET_URL(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetCarrier
            setGetCarrier(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  useEffect(() => {
    getCurrentCarrier(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralCarrier mode={getMode()} carrierData={getCarrier} onCancel={onCanceled} onConfirm={onConfirmed} />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenuCarrier tab={currentTab} updateTab={updateCurrentTab} />
              {currentTab === 'general' && (
                <GeneralCarrier
                  id={id}
                  mode={getMode()}
                  carrierData={getCarrier}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'contact' && id && (
                <ListContacts
                  contactEntity={ContactEntity.CARRIER}
                  entityId={id}
                  canAdd={true}
                  canEdit={true}
                  canRemove={true}
                  canSelect={false}
                />
              )}
              {currentTab === 'vehicles' && (
                <ListVehicles
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'carrier',
                      filterData: {
                        value: getCarrier?.id,
                        label: getCarrier?.label + ' - ' + getCarrier?.externalReference,
                        data: getCarrier,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'order' && id && (
                <ListOrders
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'carrier',
                      filterData: {
                        value: getCarrier?.id,
                        label: getCarrier?.label + ' - ' + getCarrier?.externalReference,
                        data: getCarrier,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'trip' && id && (
                <ListTrips
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'carrier',
                      filterData: {
                        value: getCarrier?.id,
                        label: getCarrier?.label + ' - ' + getCarrier?.externalReference,
                        data: getCarrier,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'documents' && getCarrier && (
                <ListAdditionalDocs entityType="carrier" entityId={getCarrier.id ?? ''} canAddNew={true} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/carriers');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/carriers');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newCarrier')
              : t('common.carrier') +
                ' ' +
                (!getCarrier
                  ? ''
                  : getCarrier.label + (getCarrier.externalReference ? '-' + getCarrier.externalReference : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className="main-container">
          <Menu selectedPage="carriers"></Menu> {/*TODO : sortir et mettre dans classe 'parent'*/}
          <div className="right detail-container">
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newCarrier')
                  : t('common.carrier') +
                    ' ' +
                    (!getCarrier
                      ? ''
                      : getCarrier.label + (getCarrier.externalReference ? '-' + getCarrier.externalReference : ''))
              }
              back={true}
              OnNotificationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              OnAutorisationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              Back={function (): void {
                history('/carriers');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

export default DetailCarrier;
