import React from 'react';
import './paragraphInput.scss';
import { Title } from './Title';

interface ParagraphInputProps {
  size?: 'small' | 'medium' | 'large';

  placeholder?: string;

  label?: string;

  onChange: (newValue: string) => void;

  error: string | undefined;

  value: string;
}

export const ParagraphInput = ({
  size = 'medium',
  label,
  placeholder,
  value,
  error,
  onChange,
  ...props
}: ParagraphInputProps) => {
  return (
    <div className="dune-content-paragraphInput">
      {label ? <span className="dune-title">{label}</span> : ''}
      {/* {label ? (
        <div className="dune-title">
          <Title label={label} type="h2" />
        </div>
      ) : (
        ""
      )} */}
      <textarea
        placeholder={placeholder ?? undefined}
        value={value ?? undefined}
        onChange={(event: any) => onChange(event?.target.value)}
        className="dune-paragraphInput"
      />
      {error ? <span className="dune-input-error">{error}</span> : ''}
    </div>
  );
};
