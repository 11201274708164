export const popupContentStyle: React.CSSProperties = {
  transition: 'all 0.3s',
  backgroundColor: '#FFFFFF',
  borderRadius: '12px',
  zIndex: 2000,
  position: 'relative',
  boxShadow: '0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)',
  overflow: 'hidden',
  maxWidth: '850px',
  minWidth: '500px',
  maxHeight: '80%',
  padding: '30px 30px',
  overflowY: 'scroll',
};

export const popupOverlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 99,
  backgroundColor: 'rgba(244, 244, 244, 0.9)',
};

export default { popupContentStyle, popupOverlayStyle };
