import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';

import { Product } from '@dune-manager/backend-core/dist/models';
import DetailProduct from '../pages/Products/DetailProduct';

export interface SelectProductOption extends SelectDataOption {
  data: Product;
}

export const SelectProduct = ({
  setValue,
  register,
  registerName = 'products',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectProductOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectProductOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const fetchData = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/product/get';
      const url = orgid + `${baseUrl}`;

      axios
        .post(
          Config.get_API_EXTRANET_URL(url),
          { label: filterText, externalReference: filterText, isActive: true },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: 1,
              limit: 50,
            },
          },
        )
        .then((res) => {
          setIsLoading(false);
          setFetchedOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    } else {
      setFetchedOptions([]);
    }
  };

  const setFetchedOptions = (fetchedOptions: Product[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label + (s.externalReference ? ' - ' + s.externalReference : ''),
          data: s,
        };

        options.push(option);
      });
      setOptions(options);
    }
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  function addPopupClosedHandler(createdId: string | undefined) {
    setShowAddPopup(false);

    if (createdId) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/product/detail/';
      const url = orgid + baseUrl + createdId;

      axios
        .get(Config.get_API_EXTRANET_URL(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setCreatedOption({
            value: res.data.content.id,
            label:
              res.data.content.label +
              (res.data.content.externalReference ? ' - ' + res.data.content.externalReference : ''),
            data: res.data.content,
          });
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    }
  }

  return (
    <>
      {showAddPopup && <DetailProduct mode="add" isPopup={true} onClosePopup={addPopupClosedHandler} />}
      <SelectData
        title={t('common.product')}
        placeholder={t('common.productChoose')}
        titleSize={props.titleSize ?? 'large'}
        hoverDescription={props.hoverDescription}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchData}
        onClickNoResult={function (): void {
          setShowAddPopup(true);
        }}
        isPopup={isPopup}
        onClosePopup={onClosePopup}
        inRow={props.inRow}
        isClearable={props.isClearable ?? true}
        noResultText={props.noResultTextOverride}
      ></SelectData>
    </>
  );
};
