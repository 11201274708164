import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Toast from '../models/Toast';

export interface ToastsState {
  tempToasts: Toast[];
  fixedToasts: Toast[];
}
const sliceInitialState: ToastsState = {
  tempToasts: [],
  fixedToasts: [],
};

const toastsSlice = createSlice({
  name: 'toasts',
  initialState: sliceInitialState,
  reducers: {
    reset: () => sliceInitialState,
    addTempToast(state, action: PayloadAction<Toast>) {
      state.tempToasts = [...state.tempToasts, action.payload];
    },
    deleteTempToast(state, action: PayloadAction<string>) {
      state.tempToasts = state.tempToasts.filter((x) => x.id !== action.payload);
    },
    setTempToasts(state, action: PayloadAction<Toast[]>) {
      state.tempToasts = action.payload;
    },
    addFixedToast(state, action: PayloadAction<Toast>) {
      state.fixedToasts = [...state.fixedToasts, action.payload];
    },
    deleteFixedToast(state, action: PayloadAction<string>) {
      state.fixedToasts = state.fixedToasts.filter((x) => x.id !== action.payload);
    },
    setFixedToasts(state, action: PayloadAction<Toast[]>) {
      state.fixedToasts = action.payload;
    },
  },
});

export const toastsActions = toastsSlice.actions;

export default toastsSlice.reducer;
