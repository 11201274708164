import { ReactNode } from 'react';
import './fixbar.scss';

interface FixBarProps {
  children?: ReactNode | undefined;
}

export const FixBar = ({ ...props }: FixBarProps) => {
  return (
    <div className="dune-fix">
      <div className="dune-fix-button">{props.children}</div>
    </div>
  );
};
