import React, { useEffect, useState } from 'react';
import { SelectCustomerOption } from '../../forms/SelectCustomer';
import { SelectBuildingSite, SelectBuildingSiteOption } from '../../forms/SelectBuildingSite';
import { useForm } from 'react-hook-form';
// import './GeneralContract.scss';
import axios from 'axios';
import * as yup from 'yup';
import Config from '../../../Config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Body } from '../../../stories/dune/atoms/Body';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Button } from '../../../stories/dune/atoms/Button';
import { Title } from '../../../stories/dune/atoms/Title';
import moment from 'moment';
import { Input } from '../../../stories/dune/atoms/Input';
import { ProductItems } from '../../forms/ProductItems';
import { useNavigate } from 'react-router-dom';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import { productItemsActions, ProductItemRow } from '../../../store/productItems-slice';
import { duplicationActions } from '../../../store/duplication-slice';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../store';
import { StaticDataState } from '../../../store/staticData-slice';
import { VatRate } from '../../../models/VatRate';
import {
  GetQuotation as GetContract,
  QuotationProductSave as ContractProductSave,
  QuotationProgressStatus as ContractProgressStatus,
  QuotationSave as ContractSave,
  QuotationStatus as ContractStatus,
  QuotationStatus,
} from '../../../models/Quotation';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { ErrorLine } from '../../../stories/dune/atoms/ErrorLine';
import stringUtils from '../../../utils/stringUtils';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import { ProductTaxes, TransportProductType } from '../../../models/Product';
import { CustomerTypeProductPrice, GetCustomerTypeProductPriceParameters } from '../../../models/CustomerType';
import Customer from '../../../models/Customer';
import BuildingSite from '../../../models/BuildingSite';
import { GlobalParameter } from '../../../models/GlobalParameter';

interface GeneralContractProps {
  id?: string;
  contractData: GetContract | undefined;
  contractCustomer: Customer | undefined;
  contractBuildingSite: BuildingSite | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;
  onReload?: () => void;

  mode: string;

  contractMode?: 'customer' | 'buildingSite';
  isEditable?: boolean;
}

const GeneralContract = React.memo((props: GeneralContractProps) => {
  // #region const init

  // #region generic
  const dispatch = useDispatch();
  const staticData = useSelector((state: { staticData: StaticDataState }) => state.staticData);

  const history = useNavigate();

  const { t } = useTranslation();

  const { addToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [formMode, setFormMode] = useState<'' | 'customer' | 'buildingSite'>('');

  const [isEditable, setIsEditable] = useState(props.isEditable || props.mode === 'add');

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific

  const [saveContract, setSaveContract] = useState<ContractSave>();

  const [customerId, setCustomerId] = useState('');
  const [customerOption, setCustomerOption] = useState<SelectCustomerOption | null>(
    props.contractData?.customerId
      ? {
          value: props.contractData.customerId ?? '',
          label: props.contractData.customer.label ?? '',
          data: props.contractData.customer,
        }
      : null,
  );
  const [buildingSiteId, setBuildingSiteId] = useState<string>();
  const [buildingSiteOption, setBuildingSiteOption] = useState<SelectBuildingSiteOption | null>(null);

  const [dateStart, setDateStart] = useState<Date>(new Date());
  const [dateEnd, setDateEnd] = useState<Date>();

  const [customerOrderNumber, setCustomerOrderNumber] = useState<string>();

  const [currentPeriodContracts, setCurrentPeriodContracts] = useState<GetContract[]>([]);
  const [isLoadingCurrentPeriodContracts, setIsLoadingCurrentPeriodContracts] = useState(false);
  const [customerTypeProductPrices, setCustomerTypeProductPrices] = useState<CustomerTypeProductPrice[]>([]);
  const [hideRep, setHideRep] = useState(false);

  const [showFillCustomerOrderNumber, setShowFillCustomerOrderNumber] = useState(false);

  const [productError, setProductError] = useState<string>();
  const [priceValidationWarning, setPriceValidationWarning] = useState<string>();
  const [priceWarningAccepted, setPriceWarningAccepted] = useState<boolean>(false);

  const [periodError, setPeriodError] = useState<string>();
  const [periodValidationWarning, setPeriodValidationWarning] = useState<string>();
  const [concurrentOrderClosureAccepted, setConcurrentOrderClosureAccepted] = useState<boolean>(false);

  const [onConfirmOverride, setOnConfirmOverride] = useState<(() => void) | undefined>(undefined);

  const schema = yup.object().shape({
    client: yup.object().shape({
      value: yup.string().nullable().required('Le client est obligatoire'),
      label: yup.string().nullable().required('Le client est obligatoire'),
    }),
    dateStart: yup.date().default(new Date()).required('La date de début est obligatoire'),
  });

  interface IFormInputs {
    client: {
      value: string | null;
      label: string | null;
    };
    chantier: {
      value: string | null;
      label: string | null;
    };
    products: {
      value: string;
      label: string;
    };
    dateStart: Date;
    dateEnd: Date | null;
  }

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // #endregion specific

  // #endregion const init

  // #region event handling

  useEffect(() => {
    setIsLoading(true);
    dispatch(productItemsActions.reset());
    if (props.contractMode && formMode !== props.contractMode) updateFormMode(props.contractMode);

    if (props.contractCustomer) {
      updateCustomer(props.contractCustomer);
    }
    if (props.contractBuildingSite) {
      updateFormMode('buildingSite');
      updateBuildingSite(props.contractBuildingSite);
    }

    if (props.contractData && staticData.vatRates.length > 0) {
      if (props.contractData.quotationType != 'Contract') {
        addToast(t('common.wrongDataTypeError'), ToastTypes.error);
        history('/contracts');
      }

      // on initialise SaveContract pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveContract({
        quotationType: 'Contract',
        status: ContractStatus.VALIDATED,
        customerId: props.contractData.customerId,
        dateStart: props.contractData.dateStart ?? new Date(),
        dateEnd: props.contractData.dateEnd,
        buildingSiteId: props.contractData.buildingSiteId,
        externalReference: props.contractData.externalReference,
        dataCustom: props.contractData.dataCustom,
        products: props.contractData.products.map((x: any) => {
          return {
            productId: x.id,
            vatRateId: x.QuotationProduct.vatRateId,
            quotedQuantity: x.QuotationProduct.quotedQuantity,
            genericPrice: x.QuotationProduct.genericPrice,
            mainProduct: x.QuotationProduct.mainProduct,
            externalReference: x.QuotationProduct.externalReference,
            comment: x.QuotationProduct.comment,
            quotedPrice: x.QuotationProduct.quotedPrice,
            discount: x.QuotationProduct.discount,
            transportMode: x.QuotationProduct.transportMode,
            transportPrices: x.QuotationProduct.contractProductTransportPrices
              ? x.QuotationProduct.contractProductTransportPrices.map((y: any) => {
                  return {
                    vatRateId: y.vatRateId,
                    price: y.price,
                    buildingSiteId: y.buildingSiteId,
                  };
                })
              : [],
          };
        }),
      });

      if (props.contractData.customer) {
        setCustomerId(props.contractData.customerId);
        setValue('client', {
          value: props.contractData.customer.id ?? '',
          label: props.contractData.customer.label ?? '',
        });
        setCustomerOption({
          value: props.contractData.customer.id ?? '',
          label: props.contractData.customer.label ?? '',
          data: props.contractData.customer as Customer,
        });
      }
      if (props.contractData.buildingSite) {
        updateFormMode('buildingSite');
        setBuildingSiteOption({
          value: props.contractData.buildingSite.id ?? '',
          label: props.contractData.buildingSite.label ?? '',
          searchParentRef: props.contractData.customer.id,
          data: props.contractData.buildingSite as BuildingSite,
        });
      }

      setDateStart(props.contractData.dateStart ?? new Date());
      setValue('dateStart', props.contractData.dateStart ?? new Date());
      setDateEnd(props.contractData.dateEnd);

      const products: ProductItemRow[] = [];

      const vatRates: VatRate[] = staticData.vatRates;

      props.contractData.products?.map((x: any) => {
        const isTransportService = TransportProductType === x.productTypeId || x.productType === 'Service Transport';

        products.push({
          id: x.QuotationProduct.id,
          productId: x.id,
          label: x.label,
          desc: x.externalReference,
          isService: isTransportService,
          isTransport: isTransportService,
          quantityIsSum: false,
          externalReference: x.QuotationProduct.externalReference,
          unit: x.unit,
          weightByUnit: x.weightByUnit,
          orderedQuantity: x.QuotationProduct.quotedQuantity,
          deliveredQuantity: undefined,
          baseUnitPrice: x.QuotationProduct.genericPrice, // en modif, on garde le genericPrice stocké lors de la création
          itemUnitPrice: x.QuotationProduct.quotedPrice,
          discountPercent: x.QuotationProduct.discount,
          vatRate: vatRates.length > 0 ? vatRates.find((y) => y.id === x.QuotationProduct.vatRateId)?.vatRate : 0.2,
          vatRateId: x.QuotationProduct.vatRateId,
          repValue:
            props.contractData?.status &&
            ContractProgressStatus.IN_PROGRESS.indexOf(props.contractData?.status as ContractStatus) > -1
              ? x.repValue
              : x.QuotationProduct.repValue ?? 0,
          tgapValue:
            props.contractData?.status &&
            ContractProgressStatus.IN_PROGRESS.indexOf(props.contractData?.status as ContractStatus) > -1
              ? x.isSubjectToTgap
                ? ProductTaxes.TGAP
                : 0
              : x.QuotationProduct.tgapValue,
          mainProduct: false,
          enableTransportPrice:
            x.QuotationProduct.contractProductTransportPrices &&
            x.QuotationProduct.contractProductTransportPrices.length > 0,
          showTransportDetails: false,
          transportMode: x.QuotationProduct.transportMode,
        });
      });

      dispatch(productItemsActions.setProducts(products));
    }

    setIsLoading(false);
  }, [props.contractData, props.contractCustomer, props.contractBuildingSite, staticData.vatRates]);

  useEffect(() => {
    if (
      customerOption &&
      customerOption.data?.customerTypeId &&
      !customerTypeProductPrices.find((x) => x.customerTypeId === customerOption.data?.customerTypeId)
    ) {
      loadCustomerTypeProductPrices();
    }
  }, [customerOption]);

  useEffect(() => {
    const bRemoveRep =
      (customerOption?.data?.isExemptFromRepTax && !(buildingSiteOption?.data?.isSubjectToRepTax ?? false)) ||
      buildingSiteOption?.data?.isExemptFromRepTax;
    setHideRep(bRemoveRep ?? false);
  }, [customerOption, buildingSiteOption]);

  useEffect(() => {
    if (onConfirmOverride) {
      onSubmit();
    }
  }, [onConfirmOverride]);

  useEffect(() => {
    if (priceWarningAccepted) {
      onSubmit();
    }
  }, [priceWarningAccepted]);

  useEffect(() => {
    // after accepting concurrent order closure, if there are no concurrent contracts, we can submit
    if (concurrentOrderClosureAccepted && !isLoadingCurrentPeriodContracts && currentPeriodContracts.length === 0) {
      onSubmit();
      setConcurrentOrderClosureAccepted(false);
    }
  }, [concurrentOrderClosureAccepted, isLoadingCurrentPeriodContracts, currentPeriodContracts]);

  useEffect(() => {
    if (dateStart && props.contractCustomer) {
      setIsLoadingCurrentPeriodContracts(true);
      const timeOutId = setTimeout(() => loadCurrentPeriodContracts(), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [dateStart, dateEnd, props.contractCustomer]);

  const onSubmit = () => {
    setIsAddingLoading(true);
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/quotation/add';
    if (props.mode == 'update') baseUrl = orgid + '/quotation/edit/' + props.id;

    const productItems = store.getState().productItems;

    // #region validation

    let bOk = true;

    // products
    if (productItems.products.findIndex((x) => x.itemUnitPrice === undefined) !== -1) {
      bOk = false;
      setProductError(t('common.allProductsMustBePriced'));
    } else {
      setProductError(undefined);
    }
    if (productItems.products.findIndex((x) => x.itemUnitPrice === 0) !== -1) {
      if (!priceWarningAccepted) {
        let warningText = '';
        productItems.products
          .filter((x) => x.itemUnitPrice === 0)
          ?.map((x) => {
            warningText += t('common.priceZeroWarning').replace('¤', x.label) + '\n';
          });
        warningText += t('common.continueAnyway') + ' ?';
        bOk = false;
        setPriceValidationWarning(warningText);
      }
    } else {
      setProductError(undefined);
    }

    // unicity of contract in period
    if (currentPeriodContracts.length > 0) {
      bOk = false;

      if (props.contractBuildingSite) {
        setPeriodError(t('common.contractAlreadyExistsForBuildingSite'));
      } else {
        let warningText = t('common.contractAlreadyExistsInPeriod') + ' : \n';
        if (
          currentPeriodContracts.length === 1 &&
          moment(currentPeriodContracts[0].dateStart ?? dateStart) < moment(dateStart) &&
          (currentPeriodContracts[0].dateEnd ?? null) === null
        ) {
          // if there is only one concurrent contract and it is older and still in progress
          // let the user decide if they want to set the end date to the older contract
          warningText +=
            t('common.contract') +
            ' n°' +
            currentPeriodContracts[0].quotationNumber +
            ' : ' +
            moment(currentPeriodContracts[0].dateStart).tz(siteTimeZone).format('DD/MM/YYYY HH:mm') +
            ' - ' +
            t('common.inProgress');

          warningText += '\n\n' + t('common.setEndDateToNewContractStart') + ' ?';
          setPeriodValidationWarning(warningText);
        } else {
          // display all concurrent contracts
          currentPeriodContracts.map((x) => {
            warningText +=
              t('common.contract') +
              ' n°' +
              x.quotationNumber +
              ' : ' +
              moment(x.dateStart).tz(siteTimeZone).format('DD/MM/YYYY HH:mm') +
              ' - ' +
              (x.dateEnd ? moment(x.dateEnd).tz(siteTimeZone).format('DD/MM/YYYY HH:mm') : t('common.inProgress')) +
              '\n';
          });
          setPeriodError(warningText);
        }
      }
    }

    // date coherence
    if (dateEnd !== undefined && moment(dateStart) > moment(dateEnd)) {
      bOk = false;
      setPeriodError(t('common.endDateBeforeStartDate'));
    }

    // #endregion validation

    // #region saving

    if (bOk) {
      // construct Objet product from tripProducts to send
      const products_send: ContractProductSave[] = [];

      productItems.products?.map((product: ProductItemRow) => {
        products_send.push({
          productId: product.productId,
          vatRateId: (product.vatRateId ?? 0).toString(),
          quotedQuantity: product.orderedQuantity ?? 0,
          genericPrice: product.baseUnitPrice ?? 0,
          mainProduct: false,
          discount: product.discountPercent ?? 0,
          quotedPrice: product.itemUnitPrice ?? 0,
          repValue: product.repValue ?? 0,
          tgapValue: product.tgapValue ?? 0,
          comment:
            saveContract &&
            saveContract.products &&
            saveContract.products.filter((x) => x.productId === product.productId).length > 0
              ? saveContract.products.filter((x) => x.productId === product.productId)[0].comment
              : undefined,
          externalReference:
            saveContract &&
            saveContract.products &&
            saveContract.products.filter((x) => x.productId === product.productId).length > 0
              ? saveContract.products.filter((x) => x.productId === product.productId)[0].externalReference
              : undefined,
          transportMode: product.transportMode,
          transportPrices: [],
        });
      });
      const dataToSend: ContractSave = {
        ...saveContract,
        quotationType: 'Contract',
        status: ContractStatus.CREATED,
        products: products_send,
        customerId: customerId,
        buildingSiteId: buildingSiteId,
        dateStart: dateStart,
        dateEnd: dateEnd,
      };

      axios
        .post(Config.get_API_EXTRANET_URL(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsAddingLoading(false);
          addToast(t('common.contract' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          if (!onConfirmOverride) props.onConfirm(res.data.content.uuid);
          else onConfirmOverride();
          if (props.mode === 'add') {
            // setShowFillCustomerOrderNumber(true); // TODO : duplicate as order
          }
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }

    // #endregion saving
  };

  function loadCurrentPeriodContracts() {
    if (props.contractCustomer && ((dateEnd ?? null) === null || moment(dateStart) <= moment(dateEnd))) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/quotation/get';
      const url = Config.get_API_EXTRANET_URL(baseUrl);

      const dataToSend = {
        customerId: props.contractCustomer.id,
        buildingSiteId: props.contractBuildingSite?.id,
        quotationType: 'Contract',
        dateStart: dateStart,
        dateEnd: dateEnd,
      };

      axios
        .post(url, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.content.items.length > 0) {
            // si chantier, erreur
            if (
              props.contractBuildingSite &&
              res.data.content.items.filter((x: GetContract) => x.id !== props.id).length > 0
            ) {
              setPeriodError(t('common.contractAlreadyExistsForBuildingSite'));
              setCurrentPeriodContracts(res.data.content.items.filter((x: GetContract) => x.id !== props.id));
            } else {
              // on enregistre GetContract
              let concurrentContracts = res.data.content.items.filter(
                (x: GetContract) =>
                  x.id !== props.id &&
                  (x.buildingSiteId ?? undefined) === props.contractBuildingSite?.id &&
                  moment(x.dateEnd) !== moment(dateStart),
              );
              setCurrentPeriodContracts(concurrentContracts);

              let warningText = t('common.contractAlreadyExistsInPeriod') + ' : \n';
              concurrentContracts = concurrentContracts.filter(
                (x: GetContract) => x.id !== props.id && x.dateStart !== dateEnd,
              );
              if (
                concurrentContracts.length > 0 &&
                !(
                  concurrentContracts.length === 1 &&
                  moment(concurrentContracts[0].dateStart ?? dateStart) < moment(dateStart) &&
                  (concurrentContracts[0].dateEnd ?? null) === null
                )
              ) {
                // display all concurrent contracts
                concurrentContracts.map((x: GetContract) => {
                  warningText +=
                    t('common.contract') +
                    ' n°' +
                    x.quotationNumber +
                    ' : ' +
                    moment(x.dateStart).tz(siteTimeZone).format('DD/MM/YYYY HH:mm') +
                    ' - ' +
                    (x.dateEnd
                      ? moment(x.dateEnd).tz(siteTimeZone).format('DD/MM/YYYY HH:mm')
                      : t('common.inProgress')) +
                    '\n';
                });
                setPeriodError(warningText);
              } else setPeriodError(undefined);
            }
          } else {
            setPeriodError(undefined);
            setCurrentPeriodContracts([]);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
    setIsLoadingCurrentPeriodContracts(false);
  }

  function loadCustomerTypeProductPrices() {
    if (!customerOption?.data?.customerTypeId) return;

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/customer-type-product-price/get';

    const dataToSend: GetCustomerTypeProductPriceParameters = { customerTypeId: customerOption?.data?.customerTypeId };

    axios
      .post(Config.get_API_EXTRANET_URL(baseUrl), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCustomerTypeProductPrices(res.data.content.items);
        dispatch(productItemsActions.setCustomerTypeProductPrices({ prices: res.data.content.items, bRefresh: false }));
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  function convertToOrder(customerOrderNumber: string) {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/quotation/convert-to-order/' + props.id;

    axios
      .post(
        Config.get_API_EXTRANET_URL(baseUrl),
        { customerOrderNumber: customerOrderNumber.length === 0 ? null : customerOrderNumber },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        setIsAddingLoading(false);
        addToast(t('common.orderCreated'), ToastTypes.success);
        if (props.onReload) props.onReload();
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }
  function handleValidateContract() {
    setShowFillCustomerOrderNumber(true);
  }
  function handleSetOlderContractEndDate(olderContract: GetContract) {
    setIsAddingLoading(true);
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    // first we get the older contract details
    const baseUrl = orgid + '/quotation/detail/' + olderContract.id;
    axios
      .get(Config.get_API_EXTRANET_URL(baseUrl), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const olderContractDetails = res.data.content;
        // then we update the end date
        const baseUrl = orgid + '/quotation/edit/' + olderContractDetails.id;
        const dataToSend: ContractSave = {
          ...olderContractDetails,
          dateEnd: moment(dateStart, 'YYYY-MM-DD').subtract(1, 'second').toDate(),
          products: olderContractDetails.products.map((x: any) => x.QuotationProduct),
        };
        axios
          .post(Config.get_API_EXTRANET_URL(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setIsAddingLoading(false);
            addToast(t('common.contractUpdated'), ToastTypes.success);
            loadCurrentPeriodContracts();
            setConcurrentOrderClosureAccepted(true);
          })
          .catch((error) => {
            setIsAddingLoading(false);
            if (error.response) {
              if (
                error.response.data.code == 'ERR4010001' ||
                error.response.data.code == 'ERR4031001' ||
                error.response.data.code == 'ERR4010000'
              ) {
                history('/');
              }
            }
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          });
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }
  function handleDuplicateAsQuotation() {
    if (props.contractData) {
      const dataToDuplicate = props.contractData;
      dataToDuplicate.status = QuotationStatus.CREATED;
      dataToDuplicate.quotationComment =
        '\n' +
        t('common.duplicatedFrom') +
        ' ' +
        t('common.contract').toLowerCase() +
        ' n°' +
        props.contractData?.quotationNumber;
      dataToDuplicate.quotationType = 'Quotation';
      dispatch(duplicationActions.addQuotationToDuplicate(dataToDuplicate));
      history('/quotations/add/' + (formMode === 'buildingSite' ? 'buildingSite' : 'customer'));
    }
  }
  function handleDuplicateAsContract() {
    if (props.contractData) {
      const dataToDuplicate = props.contractData;
      dataToDuplicate.status = QuotationStatus.VALIDATED;
      dataToDuplicate.quotationComment =
        '\n' +
        t('common.duplicatedFrom') +
        ' ' +
        t('common.contract').toLowerCase() +
        ' n°' +
        props.contractData?.quotationNumber;
      dataToDuplicate.quotationType = 'Contract';
      dispatch(duplicationActions.addQuotationToDuplicate(dataToDuplicate));
      history(
        '/contracts/add/' +
          (formMode === 'buildingSite'
            ? 'buildingSite/' + props.contractData.buildingSiteId
            : 'customer/' + props.contractData.customerId),
      );
    }
  }
  function handleDuplicateAsOrder() {
    if (props.contractData) {
      const dataToDuplicate = props.contractData;
      dispatch(duplicationActions.duplicateQuotationAsOrder(dataToDuplicate));
      history(
        '/orders/add/' + (formMode === 'buildingSite' ? 'buildingSite/' + buildingSiteId : 'customer/' + customerId),
      );
    }
  }
  async function handleGoToOrder() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/order/get-by-quotation-id/' + props.contractData?.id;

    const response = await axios.get(Config.get_API_EXTRANET_URL(baseUrl), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const orderId = response.data.content.items[0].id;
    history(`/orders/${orderId}/update`);
  }

  function updateFormMode(newState?: string) {
    setFormMode((prevState) => {
      if (newState === undefined) newState = prevState === 'buildingSite' ? 'customer' : 'buildingSite';
      return newState === 'buildingSite' ? 'buildingSite' : 'customer';
    });
  }

  function updateBuildingSite(newValue: BuildingSite) {
    setBuildingSiteId(newValue?.id ?? '');
    if (newValue && customerId !== newValue.customerId && newValue.customer) {
      setCustomerId(newValue?.customerId ?? '');
      setCustomerOption({
        value: newValue?.customerId ?? '',
        label: newValue?.customer?.label ?? '',
        data: newValue?.customer,
      });
      setSaveContract((prevValue) => {
        if (prevValue) return { ...prevValue, buildingSiteId: newValue?.id, customerId: newValue?.customerId ?? '' };
      });
    } else {
      setSaveContract((prevValue) => {
        if (prevValue) return { ...prevValue, buildingSiteId: newValue?.id };
      });
    }
    setBuildingSiteOption({
      value: newValue?.id ?? '',
      label: newValue?.label ?? '',
      data: newValue,
    });
    setValue('chantier', {
      value: newValue?.id ?? '',
      label: newValue?.label ?? '',
    });
  }
  function updateCustomer(newValue: Customer) {
    setCustomerId(newValue?.id ?? '');
    setSaveContract((prevValue) => {
      if (prevValue) return { ...prevValue, customerId: newValue?.id ?? '' };
    });
    setCustomerOption({
      value: newValue.id ?? '',
      label: newValue.label ?? '',
      data: newValue,
    });
    setValue('client', {
      value: newValue.id ?? '',
      label: newValue.label ?? '',
    });
  }

  let selectPopup = <></>;
  if (showFillCustomerOrderNumber) {
    selectPopup = (
      <Input
        error={''}
        label={t('common.fillCustomerOrderRefForOrder')}
        type="text"
        value={customerOrderNumber ?? ''}
        placeholder=""
        isPopup={true}
        onClosePopup={(selectedOption: any) => {
          convertToOrder(selectedOption ?? '');
          setShowFillCustomerOrderNumber(false);
        }}
        onChange={function (newValue: string | number): void {
          setCustomerOrderNumber(newValue.toString());
        }}
        popupConfirmLabel={t('common.validate')}
        popupConfirmIcon="valid"
      />
    );
  }
  if (priceValidationWarning) {
    selectPopup = (
      <PopupContainer
        message={priceValidationWarning}
        onCancel={function (): void {
          setPriceValidationWarning(undefined);
        }}
        onConfirm={function (): void {
          setPriceValidationWarning(undefined);
          setPriceWarningAccepted(true);
        }}
        confirmLabel={t('common.save')}
        confirmIcon="save"
        cancelLabel={t('common.return')}
      />
    );
  }
  if (periodValidationWarning) {
    selectPopup = (
      <PopupContainer
        message={periodValidationWarning}
        onCancel={function (): void {
          setPeriodValidationWarning(undefined);
        }}
        onConfirm={function (): void {
          setPeriodValidationWarning(undefined);
          // set end date to the older contract
          handleSetOlderContractEndDate(currentPeriodContracts[0]);
        }}
        confirmLabel={t('common.yes')}
        confirmIcon="save"
        cancelLabel={t('common.no')}
      />
    );
  }

  // #region productTransportPrices

  // #endregion event handling

  return (
    <>
      <Body>
        <div className="detail-content">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <form className="form">
              <p className="validator-error">{errors.client?.message}</p>
              <p className="validator-error">{errors.chantier?.message}</p>
              <p className="validator-error">{errors.products?.message}</p>
              <div className="form-section" title={t('common.contractPeriodDesc')}>
                <Title label={t('common.contractPeriod')} type="title1" />
                <FormRow>
                  <div className="panelInput">
                    <Input
                      error={errors.dateStart?.message ?? ''}
                      label={t('common.start')}
                      type="date"
                      {...register('dateStart')}
                      value={moment(dateStart)?.toISOString()?.substring(0, 10)}
                      placeholder="DD/MM/YY HH:mm"
                      onChange={(newValue: string | number) => {
                        if ((newValue ?? '') === '')
                          setDateStart(new Date(new Date()?.toISOString()?.substring(0, 10)));
                        else setDateStart(new Date(new Date(newValue as string)?.toISOString()?.substring(0, 10)));
                      }}
                    />
                    <p className="validator-error">{errors.dateStart?.message}</p>
                  </div>
                  <div className="panelInput">
                    <Input
                      error={errors.dateEnd?.message ?? ''}
                      label={t('common.end')}
                      type="date"
                      {...register('dateEnd')}
                      value={dateEnd !== undefined ? moment(dateEnd)?.toISOString()?.substring(0, 10) : undefined}
                      placeholder="DD/MM/YY HH:mm"
                      onChange={(newValue: string | number) => {
                        if ((newValue ?? '') === '') setDateEnd(undefined);
                        else setDateEnd(new Date(new Date(newValue as string)?.toISOString()?.substring(0, 10)));
                      }}
                    />
                    <p className="validator-error">{errors.dateEnd?.message}</p>
                  </div>
                </FormRow>
                {periodError && <ErrorLine label={periodError} />}
              </div>
              <div className="form-section">
                <FormRow>
                  <Title label={t('common.products')} type="title1" />
                </FormRow>
                {productError && <ErrorLine label={productError} />}
                <ProductItems
                  label={t('common.products')}
                  canAdd={isEditable}
                  canRemove={isEditable}
                  isLoading={isLoading}
                  showTransportMode={true}
                  canEditTransportMode={isEditable}
                  showOrderedQuantity={false}
                  canEditOrderedQuantity={false}
                  showUnitPrice={true}
                  canEditUnitPrice={isEditable}
                  showDiscountValue={true}
                  canEditDiscountValue={true}
                  showProductPrice={false}
                  showRowExVatPrice={false}
                  showRowInclVatPrice={true}
                  showVatRate={true}
                  canEditVatRate={isEditable}
                  showTotal={false}
                  showTotalDetails={false}
                  hideIncludedTransportMode={formMode !== 'buildingSite'}
                  hideRep={hideRep}
                />
              </div>
              {!isLoading && (
                <div style={{ marginTop: '75px' }}>
                  <FixBar>
                    <Button label={t('common.return')} style="white" onClick={props.onCancel} />
                    {isEditable && (
                      <Button
                        iconLeft="save"
                        label={t('common.save')}
                        style="primary"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoadingCurrentPeriodContracts}
                      />
                    )}
                    {props.mode == 'update' && (
                      <>
                        {props.contractData?.status === ContractStatus.VALIDATED && (
                          <Button
                            iconLeft="eye"
                            label={t('common.seeOrder')}
                            style="primary-03"
                            onClick={handleGoToOrder}
                          />
                        )}
                        <Button
                          iconLeft="add"
                          label={t('common.generateQuotation')}
                          style="primary-02"
                          onClick={handleDuplicateAsQuotation}
                        />
                        {formMode !== 'buildingSite' && (
                          <Button
                            iconLeft="add"
                            label={t('common.duplicateContract')}
                            style="primary-02"
                            onClick={handleDuplicateAsContract}
                          />
                        )}
                        <Button
                          iconLeft="add"
                          label={t('common.generateOrder')}
                          style="primary-02"
                          onClick={handleDuplicateAsOrder}
                        />
                      </>
                    )}
                  </FixBar>
                </div>
              )}
            </form>
          )}
        </div>
        {selectPopup}
      </Body>
    </>
  );
});

export default GeneralContract;
