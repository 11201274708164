import React, { useEffect, useState } from 'react';
import '../styles/table.scss';
import '../styles/popup.scss';
import axios from 'axios';
import Config from '../Config';
import { Alert } from '@dune-manager/backend-core/dist/models';
import { ReactComponent as SvgReduce } from '../assets/svg/Reduce.svg';
import { ReactComponent as SvgRefresh } from '../assets/svg/refresh.svg';
import { ReactComponent as SvgBox } from '../assets/svg/box.svg';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import eventBus from '../utils/EventBus';
import './Notification.scss';
import { useTranslation } from 'react-i18next';

//import Roles from '../utils/Roles';

interface NotificationProps {
  from?: string;
  parentCallback?: any;
  refresh?: boolean;
}

const Notification = React.memo((props: NotificationProps) => {
  const { t } = useTranslation();
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '1 sec',
      ss: '%d sec',
      m: '1 min',
      mm: '%d min',
      h: '1 h',
      hh: '%d h',
      d: '1 j',
      dd: '%d j',
      w: '1 semaine',
      ww: '%d sem.',
      M: '1 mois',
      MM: '%d mois',
      y: '1 an',
      yy: '%d ans',
    },
  });
  const [alert, setAlert] = useState<Alert[]>();
  const history = useNavigate();
  const alertBox: any = [];
  let alertCount = 0;

  const fetchAlerts = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/alert/get?limit=5&sortBy=createdAt&orderBy=desc';

    axios
      .post(
        Config.get_API_EXTRANET_URL(url),
        { acknowledged: false },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setAlert(res.data.content.items); // Données terminal
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
      });
  };

  useEffect(() => {
    eventBus.on('new_alert', () => {
      fetchAlerts();
    });
  }, []);

  useEffect(() => {
    if (!props.refresh) {
      fetchAlerts();
    }
  }, [props.refresh]);

  const CloseSidebar = () => {
    props.parentCallback(true);
  };

  const RefreshAlert = () => {
    fetchAlerts();
  };

  alertCount = alert?.length ?? 0;
  alert?.forEach((alert: any) => {
    alertBox.push(
      <div className="box_terminal" key={`alert-${alert.id}`}>
        <div className="popup-subtitle">
          <div className="popup-subtitle-icon">
            <SvgBox className="svg" />
          </div>
          <div className="popup-subtitle-description">
            <div className="popup-subtitle-description-header">
              <div className="popup-subtitle-description-content">
                <h1 className="base2 title">
                  {alert?.description?.slice(0, 20)}
                  {alert?.description?.length > 20 ? ' ...' : ''}
                </h1>
              </div>
              <div className="popup-subtitle-description-content">
                <h1 className="base2 time">{moment(alert.createdAt).fromNow()}</h1>
              </div>
            </div>
            <div>
              <h1 className="base2 description">{alert.origin}</h1>
              <h1 className="base2 description">{alert.alertTypeDescription}</h1>
            </div>
          </div>
        </div>
      </div>,
    );
  });

  return (
    <div className="popup_barrier">
      <div className="popup-header">
        <div className="popup-title">
          <div className="terminalTitle">
            <h3 className="title1SemiBold">{t('common.notification')}</h3>
          </div>
          <div className="terminalCounter">
            <h3 className="title1SemiBold">{alertCount}</h3>
          </div>
        </div>
        <div className="teminalHeaderButton">
          <div className="Btn" onClick={RefreshAlert}>
            <SvgRefresh className="svg" />
          </div>
          <div className="Btn" onClick={CloseSidebar}>
            <SvgReduce className="svg" />
          </div>
        </div>
      </div>
      <div className="popup-body-alert">
        <div className="popup-body-alert-title">
          <div>
            <h1 className="base2 neutral4">{t('menu.alerts')}</h1>
          </div>
          <div>
            <Link to={'/alertes'} onClick={CloseSidebar}>
              <h3 className="base2 primary1">{t('common.viewAll')}</h3>
            </Link>
          </div>
        </div>
        <div className="popup-body-alert-content">{alertBox}</div>
      </div>
    </div>
  );
});

export default Notification;
