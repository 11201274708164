import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZoneType } from '../../models/Zone';

export interface SelectZoneTypeOption extends SelectDataOption {}

export const SelectZoneType = ({
  setValue,
  register,
  registerName = 'zoneType',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectZoneTypeOption[]>([]);

  const [defaultOption, setDefaultOption] = useState<SelectZoneTypeOption | undefined>(undefined);

  const zoneTypeList = {
    process: t('zoneType.' + ZoneType.PROCESS),
    transit: t('zoneType.' + ZoneType.TRANSIT),
    waiting: t('zoneType.' + ZoneType.WAITING),
  };

  const loadOptions = () => {
    const optionsZoneType: SelectZoneTypeOption[] = [];

    if (zoneTypeList != null) {
      for (const [key, value] of Object.entries(zoneTypeList)) {
        const option = {
          value: key,
          label: value,
          data: { value: key, label: value },
        };

        optionsZoneType.push(option);
      }
      setOptions(optionsZoneType);
      setDefaultOption(optionsZoneType.find((x) => x.value === 'process'));
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <SelectData
      title={props.titleOverride ?? t('common.zoneType')}
      placeholder={t('common.zoneTypeChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription ?? t('common.zoneTypeDesc')}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption ?? defaultOption}
      filterText={''}
      fetchAtLoad={false}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};
