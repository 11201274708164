import React, { useEffect, useState } from 'react';
import '../styles/table.scss';
import '../styles/popup.scss';
import axios from 'axios';
import Config from '../Config';
import { Terminal } from '@dune-manager/backend-core/dist/models';
import './Barrier.scss';
import { ReactComponent as SvgReduce } from '../assets/svg/Reduce.svg';
import { useNavigate } from 'react-router-dom';
//import Roles from '../utils/Roles';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../stories/dune/atoms/FormRow';
import useToast from '../hooks/use-toast';
import { ToastTypes } from '../models/ToastTypes';

interface BarrierProps {
  from?: string;
  parentCallback?: any;
}

const Barrier = React.memo((props: BarrierProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [terminal, setTerminal] = useState<Terminal[]>();
  const { addToast } = useToast();

  const terminalBox: any = [];
  let terminalCount = 0;

  const fetchTerminals = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/terminal/get';

    axios
      .get(Config.get_API_EXTRANET_URL(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        let items = res.data.content.items;
        if (items && items.length > 0) items = items.filter((item: any) => item.genericType !== 'connector');
        setTerminal(items); // Données terminal
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  useEffect(() => {
    fetchTerminals();
  }, []);

  const onClickOpen = (e: any) => {
    const [action, id] = e.currentTarget.id.split('_');
    sendCommand(action, id);
  };

  const onClickClose = (e: any) => {
    const [action, id] = e.currentTarget.id.split('_');
    sendCommand(action, id);
  };

  const sendCommand = async (action: string, id: string) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/terminal/send-command/' + id;

    await axios
      .post(
        Config.get_API_EXTRANET_URL(url),
        { command: action },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async () => {
        addToast(t('common.barrier' + (action === 'open' ? 'Open' : 'Close')), ToastTypes.success);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const CloseSidebar = (e: any) => {
    props.parentCallback(true);
  };

  terminalCount = terminal?.length ?? 0;
  terminal?.forEach((terminal: any) => {
    terminalBox.push(
      <div className="box_terminal" key={`barrier-${terminal.id}`}>
        <div className="popup-subtitle">
          <h1 className="base2">{terminal.label}</h1>
        </div>

        <FormRow>
          <div className="btn_Close" onClick={onClickClose} id={'close_' + terminal.id}>
            <div className="base2">{t('common.close')}</div>
          </div>

          <div className="btn_Open" onClick={onClickOpen} id={'open_' + terminal.id}>
            <div className="base2">{t('common.open')}</div>
          </div>
        </FormRow>
      </div>,
    );
  });

  return (
    <div className="popup_barrier">
      <div className="popup-header">
        <div className="popup-title">
          <div className="terminalTitle">
            <h3 className="title1SemiBold">{t('common.barrier')}</h3>
          </div>
          <div className="terminalCounter">
            <h3 className="title1SemiBold">{terminalCount}</h3>
          </div>
        </div>
        <div>
          <div className="Btn" onClick={CloseSidebar}>
            <SvgReduce className="svgReduce" />
          </div>
        </div>
      </div>
      {terminalBox}
    </div>
  );
});

export default Barrier;
