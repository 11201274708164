export const customStylesSelectReactPopup = (color = '#FCFCFC', inRow = false) => {
  return {
    control: (base: any) => ({
      ...base,
      minHeight: inRow ? '40px' : '48px',
      height: inRow ? '40px' : '48px',
      background: color,
      width: '100%',
      // Overwrittes the different states of border
      borderColor: color,
      color: 'black',
    }),
    singleValue: (base: any) => ({
      ...base,
      paddingBottom: inRow ? '10px' : base.paddingBottom,
    }),
    valueContainer: (base: any) => ({
      ...base,
      minHeight: inRow ? '40px' : '48px',
      height: inRow ? '40px' : '48px',
      paddingLeft: inRow ? '5px' : base.paddingLeft,
      paddingRight: inRow ? '5px' : base.paddingRight,
    }),
    option: (base: any) => ({
      ...base,
      color: '#33383F',
    }),
    menuPortal: (base: any) => ({
      ...base,
      color: '#FCFCFC',
      zIndex: 100,
    }),
    menu: (base: any) => ({
      ...base,
      color: '#FCFCFC',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      marginLeft: inRow ? '0px' : base.marginLeft,
      marginRight: inRow ? '0px' : base.marginRight,
      paddingLeft: inRow ? '0px' : base.paddingLeft,
      paddingRight: inRow ? '0px' : base.paddingRight,
    }),
  };
};
