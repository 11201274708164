import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralProduct from './GeneralProduct';
import SubMenuProduct from './SubMenuProduct';
import { GetProduct } from '../../../models/Product';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';

interface DetailProductProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailProduct = React.memo((props: DetailProductProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast, addFixedToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
  };

  const { id, mode, autosign } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getProduct, setGetProduct] = useState<GetProduct>();

  function getCurrentProduct(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/product/detail/';
      const url = Config.get_API_EXTRANET_URL(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetProduct
            setGetProduct(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  useEffect(() => {
    getCurrentProduct(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralProduct mode={getMode()} productData={getProduct} onCancel={onCanceled} onConfirm={onConfirmed} />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenuProduct tab={currentTab} updateTab={updateCurrentTab} />
              {currentTab === 'general' && (
                <GeneralProduct
                  id={id}
                  mode={getMode()}
                  productData={getProduct}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'documents' && getProduct && (
                <ListAdditionalDocs entityType="product" entityId={getProduct.id ?? ''} canAddNew={true} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/products');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/products');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newProduct')
              : t('common.product') +
                ' ' +
                (!getProduct
                  ? ''
                  : getProduct.label + (getProduct.externalReference ? '-' + getProduct.externalReference : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className="main-container">
          <Menu selectedPage="products"></Menu> {/*TODO : sortir et mettre dans classe 'parent'*/}
          <div className="right detail-container">
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newProduct')
                  : t('common.product') +
                    ' ' +
                    (!getProduct
                      ? ''
                      : getProduct.label + (getProduct.externalReference ? '-' + getProduct.externalReference : ''))
              }
              back={true}
              OnNotificationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              OnAutorisationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              Back={function (): void {
                history('/products');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

// #region // TODO zone produits priorité
/*


  const priorityManage = (e: any) => {
    const id = e.target.parentElement.id.replace(/cell-priority-/g, '');
    setIdManagedProduct(id);
    fetchDetailProduct(id);
    setIsOpenManageZonePopup(true);
  };

  const fetchDetailProduct = (id: string) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/product/detail/' + id;

    axios
      .get(Config.get_API_EXTRANET_URL(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        populateDataZonePriority(res.data.content.zones);
      })
      .catch((error) => {
        addToast(error.response, ToastTypes.error);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const populateDataZonePriority = (items: any[]) => {
    const data: any[] = [];

    for (const item of sortBy(items, (s) => s.ProductZone.priority)) {
      data.push({
        id: item.id,
        label: item.label,
        priority: item.ProductZone.priority,
      });
    }

    setPriorityZones(data);
  };

  
  const closeManageZonePopup = () => {
    setPriorityZones([]);
    setIdManagedProduct(null);
    setIsOpenManageZonePopup(false);
  };

  const submitManageZone = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = `${orgid}/product/${idManagedProduct}/change-sorted-zones`;

    const data = {
      zones: priorityZones.map((item: any) => {
        return {
          zoneId: item.id,
          priority: item.priority,
        };
      }),
    };

    axios
      .post(Config.get_API_EXTRANET_URL(url), data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        fetchProducts(1, perPage);
        closeManageZonePopup();
        setPriorityZones([]);
        setIdManagedProduct(null);
        addToast('La priorité des zones a été mise a jour', ToastTypes.success);
      })
      .catch((error) => {
        addToast(error.response, ToastTypes.error);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

*/
// #endregion // TODO zone produits priorité

export default DetailProduct;
