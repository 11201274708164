import BuildingSite from './BuildingSite';

export enum WasteRequestStatus {
  TO_BE_COMPLETED = 'to_be_completed',
  REQUESTED = 'requested',
  REFUSED = 'refused',
  ACCEPTED = 'accepted',
}

export interface WasteRequestSave {
  status: WasteRequestStatus;
  buildingSiteId?: string;
}

export interface GetWasteRequestParameters {
  search?: string;
  wasteRequestNumber?: string;
  status?: string[];
  buildingSiteId?: string;
  customerId?: string;
  externalReference?: string;
}

export interface GetWasteRequest {
  id: string;
  wasteRequestNumber?: string;
  siteId?: string;
  siteIsSigned?: boolean;
  status?: WasteRequestStatus;
  detailUrl?: string;
  pdfUrl?: string;
  buildingSite?: BuildingSite;
  buildingSiteId?: string;
  dataCustom?: string;
  createdAt?: Date;
  carrierAddress?: string;
  carrierContact?: string;
  carrierEmail?: string;
  carrierIsSigned?: boolean;
  carrierLabel?: string;
  carrierPhone?: string;
  carrierSignerEmail?: string;
  carrierSiret?: string;
  originAddress?: string;
  originEndDate?: Date;
  originExternalReference?: string;
  originGps?: string;
  originNatureOfWork?: string;
  originParcel?: string;
  originStartDate?: Date;
  producerAddress?: string;
  producerContact?: string;
  producerEmail?: string;
  producerIsSigned?: boolean;
  producerLabel?: string;
  producerPhone?: string;
  producerSignerEmail?: string;
  producerSiret?: string;
  productCode?: string;
  productComment?: string;
  productLabel?: string;
  productQuantity?: number;
  productUnit?: string;
  requesterAddress?: string;
  requesterContact?: string;
  requesterEmail?: string;
  requesterIsSigned?: boolean;
  requesterLabel?: string;
  requesterPhone?: string;
  requesterSignerEmail?: string;
  requesterSiret?: string;
}
