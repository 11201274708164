import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import ProductItemsReducer, { ProductItemsState } from './productItems-slice';
import ToastsReducer, { ToastsState } from './toasts-slice';
import DuplicationReducer, { DuplicationState } from './duplication-slice';
import StaticDataReducer, { StaticDataState } from './staticData-slice';

const store = configureStore({
  reducer: {
    productItems: ProductItemsReducer,
    toasts: ToastsReducer,
    duplication: DuplicationReducer,
    staticData: StaticDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.onClick'], // Ignore the onClick function in the Toast model
      },
    }),
});

export type AppDispatch = typeof store.dispatch;

export default store;
