const tableStyles = {
  responsiveWrapper: {
    style: {
      borderRadius: '0 0 8px 8px !important',
    },
  },
  rows: {
    style: {
      minHeight: '72px', // override the row height
      borderBottomWidth: '0px !important',
      // borderBottomColor: '#EFEFEF !important',
    },
    denseStyle: {
      minHeight: '50px',
      borderBottomWidth: '1px !important',
      borderBottomColor: '#E4E4E7 !important',
    },
  },
  headRow: {
    style: {
      minHeight: '72px', // override the row height
      borderBottomWidth: '0px !important',
      // borderBottomColor: '#EFEFEF !important',
    },
    denseStyle: {
      minHeight: '50px',
      borderBottomWidth: '1px !important',
      borderBottomColor: '#E4E4E7 !important',
    },
  },
  headCells: {
    style: {
      color: '#90A0B7',
      backgroundColor: '#FDFDFF',
      paddingLeft: '15px', // override the cell padding for head cells
      paddingRight: '5px',
    },
  },
  cells: {
    style: {
      color: '#90A0B7',
      paddingLeft: '15px', // override the cell padding for data cells
      paddingRight: '5px',
    },
  },
  pagination: {
    style: {
      marginTop: '20px',
      zIndex: '0',
      border: 'none',
      borderRadius: '8px',
    },
  },
};

export default tableStyles;
