export default class StringUtils {
  static Capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  static formatFieldsForPost(obj: any): any {
    const formattedObj: any = {};

    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'string') {
        formattedObj[key] = value === '' ? null : value;
      } else {
        formattedObj[key] = value;
      }
    }

    return formattedObj;
  }
}
