/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import Config from '../Config';
import axios from 'axios';
// import { useHistory } from "react-router";
import Roles from '../utils/Roles';
import { Scopes } from '../models/Scopes';
import moment from 'moment';
import eventBus from '../utils/EventBus';
import pusherJs, { Channel } from 'pusher-js';
import Workspace from '../models/Workspace';

const EventListener = React.memo(() => {
  // const history = useHistory();

  const [currentWorksace, setCurrentWorkspace] = useState(undefined);

  useEffect(() => {
    eventBus.on('updateWorkspace', (data: any) => {
      subscribe(data.workspaceId);
    });

    let orgid = localStorage.getItem('orgid');
    subscribe(orgid);
  }, []);

  const subscribe = (uuid: string | null | undefined) => {
    if ((uuid ?? null) !== null) {
      const pusher = new pusherJs(process.env.REACT_APP_PUSHER_CHANNEL_KEY ?? '', {
        cluster: 'eu',
      });

      pusher?.allChannels().forEach((channel: Channel) => {
        pusher.unsubscribe(channel.name);
      });

      const channel1 = pusher?.subscribe(uuid as string);

      channel1?.bind('new_alert', function (data: any) {
        eventBus.dispatch('new_alert', data);
      });

      channel1?.bind('trip_has_been_updated_extranet', function (data: any) {
        eventBus.dispatch('update_trip', data);
      });

      channel1?.bind('trip_has_been_added_extranet', function (data: any) {
        eventBus.dispatch('new_trip', data);
      });

      channel1?.bind('trip_has_been_updated', function (data: any) {
        eventBus.dispatch('update_trip_with_uuid', data);
      });
      channel1?.bind('terminal_has_been_send_equipment_data_extranet', function (data: any) {
        eventBus.dispatch('terminal_has_been_updated', data);
      });
      channel1?.bind('terminal_has_been_send_all_equipments_data', function (data: any) {
        eventBus.dispatch('terminal_has_been_updated_callback', data);
      });
    }
  };
  return <></>;
});
export default EventListener;
