import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalDocType } from '../../models/AdditionalDocType';
import { useSelector } from 'react-redux';
import { StaticDataState } from '../../store/staticData-slice';

export interface SelectAdditionalDocTypeOption extends SelectDataOption {
  // data: AdditionalDocType;
}

export const SelectAdditionalDocType = ({
  setValue,
  register,
  registerName = 'additionalDocType',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectAdditionalDocTypeOption[]>([]);
  const [filterText, setFilterText] = useState('');

  const staticData = useSelector((state: { staticData: StaticDataState }) => state.staticData);

  const fetchData = (filterText: string) => {
    setIsLoading(true);

    setFetchedOptions(staticData.additionalDocTypes);

    setIsLoading(false);
  };

  const setFetchedOptions = (fetchedOptions: AdditionalDocType[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: s.description + ' - ' + s.label,
          data: s,
        };

        options.push(option);
      });
      setOptions(options);
    }
  };

  return (
    <SelectData
      title={props.titleOverride ?? t('common.additionalDocType')}
      placeholder={t('common.additionalDocTypeChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription ?? t('common.additionalDocTypeDesc')}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption}
      filterText={''}
      fetch={fetchData}
      fetchAtLoad={true}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};
