import './toastContainer.scss';
import { Toast } from '../molecules/Toast';

export interface ToastItem {
  title?: string;
  description?: string;
  backgroundColor?: string;
  onClick?: () => void;
}

interface ToastContainerProps {
  position: string;
  list: ToastItem[];
  maxToasts?: number;
}

export const ToastContainer = (props: ToastContainerProps) => {
  return (
    <div className={`notification-container ${props.position}`}>
      {props.list.map((toast: any, i: number) => {
        return props.maxToasts && i + 1 > props.maxToasts ? (
          <></>
        ) : (
          <Toast
            key={i}
            position={props.position}
            title={toast.title}
            description={toast.description}
            backgroundColor={toast.backgroundColor}
            onClick={toast.onClick}
          />
        );
      })}
    </div>
  );
};
