export enum Colors {
  primary = '#2052F5',
  green03 = '#65C366',
  red = '#EB5757',
  graphPrimary = '#0984e3',
  graphPrimaryBis = '#74b9ff',
  graphSecondary = '#00b894',
  graphSecondaryBis = '#55efc4',
  graphThird = '#e17055',
  graphThirdBis = '#fab1a0',

  grey500 = '#90a0b7',
  grey50010 = '#90a0b71a',
  grey400 = '#e3e7f2',
  grey300 = '#f5f6f9',
  grey100 = '#fdfdff',

  neutral4 = '#ffffff',
}
