const eventBus = {
  on(event: any, callback: any) {
    document.addEventListener(event, callback);
  },
  dispatch(event: any, data: any) {
    document.dispatchEvent(new CustomEvent(event, { ...data, detail: data }));
  },
  remove(event: any, callback: any) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
