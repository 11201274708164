import { ZoneType } from '@dune-manager/backend-core/dist/models/zone';
import { useTranslation } from 'react-i18next';

export default class EnumTranslate {
  static GetZoneTypeTranslated = (zonetype: string) => {
    const { t } = useTranslation();
    return {
      [ZoneType.PROCESS]: t('zone.process'),
      [ZoneType.TRANSIT]: t('zone.transit'),
      [ZoneType.WAITING]: t('zone.waiting'),
    }[zonetype];
  };
}
