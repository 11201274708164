import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetQuotation } from '../models/Quotation';
import { GetOrder, OrderProduct, ProductWithOrderProduct } from '../models/Order';

export interface DuplicationState {
  quotation: GetQuotation | undefined;
  contract: GetQuotation | undefined;
  order: GetOrder | undefined;
}
const sliceInitialState: DuplicationState = {
  quotation: undefined,
  contract: undefined,
  order: undefined,
};

const duplicationSlice = createSlice({
  name: 'duplication',
  initialState: sliceInitialState,
  reducers: {
    reset: () => {
      return sliceInitialState;
    },
    addQuotationToDuplicate(state, action: PayloadAction<GetQuotation>) {
      if (action.payload.quotationType === 'Quotation') state.quotation = action.payload;
      else if (action.payload.quotationType === 'Contract') state.contract = action.payload;
    },
    duplicateQuotationAsOrder(state, action: PayloadAction<GetQuotation>) {
      const dataToDuplicate = action.payload;
      const newData: GetOrder = {
        id: '',
        externalReference: dataToDuplicate?.externalReference,
        orderComment: dataToDuplicate?.quotationComment,
        customerOrderNumber: '',
        dateStart: dataToDuplicate?.dateStart ?? new Date(),
        dateEnd: dataToDuplicate?.dateEnd,
        buildingSiteId: dataToDuplicate.buildingSiteId,
        buildingSite: dataToDuplicate.buildingSite,
        customerId: dataToDuplicate.customerId,
        customer: dataToDuplicate.customer,
        isActive: true,
        products: dataToDuplicate.products.map((product) => {
          return {
            id: product.id,
            isActive: true,
            createdAt: product.createdAt,
            lastModificationAt: product.lastModificationAt,
            createdBy: product.createdBy,
            lastModificationBy: product.lastModificationBy,
            label: product.label,
            dataCustom: product.dataCustom,
            unit: product.unit,
            weightByUnit: product.weightByUnit,
            taricCode: product.taricCode,
            externalReference: product.externalReference,
            multiProductPriority: product.multiProductPriority,
            lastImportAt: product.lastImportAt,
            productTypeId: product.productTypeId,
            productType: product.productType,
            siteId: product.siteId,
            OrderProduct: {
              id: undefined,
              productId: product.id,
              isActive: true,
              orderedQuantity: product.QuotationProduct.quotedQuantity,
              deliveredQuantity: 0,
              mainProduct: false,
              orderProductComment: product.QuotationProduct.comment,
            } as OrderProduct,
          } as ProductWithOrderProduct;
        }),
      };
      state.order = newData;
    },
    addOrderToDuplicate(state, action: PayloadAction<GetOrder>) {
      state.order = action.payload;
    },
    clearDuplicatedData(state, action: PayloadAction<'Quotation' | 'Contract' | 'Order'>) {
      if (action.payload === 'Quotation') state.quotation = undefined;
      if (action.payload === 'Contract') state.contract = undefined;
      if (action.payload === 'Order') state.order = undefined;
    },
  },
});

export const duplicationActions = duplicationSlice.actions;

export default duplicationSlice.reducer;
