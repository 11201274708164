import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import { GetOrder } from '../../../models/Order';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import { useDispatch, useSelector } from 'react-redux';
import { DuplicationState, duplicationActions } from '../../../store/duplication-slice';
import GeneralOrder from './GeneralOrder';
import Customer from '../../../models/Customer';
import BuildingSite from '../../../models/BuildingSite';
import moment from 'moment';
import TripOrder from './TripOrder';
import SubMenuOrder from './SubMenuOrder';

interface DetailOrderProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailOrder = React.memo((props: DetailOrderProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  const dispatch = useDispatch();
  const orderDuplicateData = useSelector((state: { duplication: DuplicationState }) => state.duplication.order);

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
    orderMode: 'customer' | 'buildingSite';
    parentId?: string;
    origin?: string;
  };

  const { id, mode, orderMode: orderMode, parentId: parentId } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getOrder, setGetOrder] = useState<GetOrder>();
  const [parentCustomer, setParentCustomer] = useState<Customer>();
  const [parentBuildingSite, setParentBuildingSite] = useState<BuildingSite>();

  function getCurrentOrder(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/order/detail/';
      const url = Config.get_API_EXTRANET_URL(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetOrder
            setParentCustomer(res.data.content.customer);
            setParentBuildingSite(res.data.content.buildingSite);
            setGetOrder(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else if (orderDuplicateData && getMode() === 'add') {
      setGetOrder(orderDuplicateData);
      // if (orderDuplicateData.buildingSite) setParentBuildingSite(orderDuplicateData.buildingSite);
      // else if (orderDuplicateData.customer) setParentCustomer(orderDuplicateData.customer);
      dispatch(duplicationActions.clearDuplicatedData('Order'));
      setParentBuildingSite(undefined);
      setParentCustomer(undefined);
      setIsLoading(false);
    } else {
      if (parentId) {
        const token = localStorage.getItem('token');
        const orgid = localStorage.getItem('orgid');
        const baseUrl = orgid + '/' + (orderMode === 'buildingSite' ? 'building-site' : 'customer') + '/detail/';
        const url = Config.get_API_EXTRANET_URL(baseUrl + parentId);
        setIsLoading(true);
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if ((res.data.content ?? null) !== null) {
              // on enregistre le parent
              if (orderMode === 'buildingSite') {
                setParentBuildingSite(res.data.content);
                setParentCustomer(res.data.content.customer);
              } else {
                setParentCustomer(res.data.content);
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response) {
              if (
                error.response.data.code == 'ERR4010001' ||
                error.response.data.code == 'ERR4031001' ||
                error.response.data.code == 'ERR4010000'
              ) {
                history('/');
              }
            }
            if ((id ?? null) !== null) {
              addToast(
                (error?.response?.data?.code
                  ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                  : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
                ToastTypes.error,
              );
            }
          });
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCurrentOrder(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <div className="main-container">
      <Menu selectedPage="orders"></Menu> {/*TODO : sortir et mettre dans classe 'parent'*/}
      <div className="right detail-container">
        <Head
          title={
            getMode() === 'add'
              ? t('common.newOrder')
              : !getOrder
              ? ''
              : t('common.orderShort') +
                ' ' +
                getOrder.orderNumber +
                (getOrder.externalReference ? '-' + getOrder.externalReference : '')
          }
          breadcrumbs1={
            parentCustomer || getOrder?.customer?.id ? (
              <Link
                to={`/customers/${parentCustomer?.id ?? getOrder?.customer?.id ?? ''}/update/order`}
                className="breadcrumb-link"
              >
                {parentCustomer?.label ?? getOrder?.customer?.label ?? ''}
              </Link>
            ) : undefined
          }
          breadcrumbs2={
            parentBuildingSite || getOrder?.buildingSite?.id ? (
              <Link
                to={`/buildingSites/${parentBuildingSite?.id ?? getOrder?.buildingSite?.id ?? ''}/update/order`}
                className="breadcrumb-link"
              >
                {parentBuildingSite?.label ?? getOrder?.buildingSite?.label ?? ''}
              </Link>
            ) : undefined
          }
          titleType="h2"
          back={true}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            goBack('');
          }}
        />

        {!isLoading && (
          <>
            {getMode() === 'add' && (
              <GeneralOrder
                mode={getMode()}
                orderData={getOrder}
                orderCustomer={parentCustomer}
                orderBuildingSite={parentBuildingSite}
                onCancel={onCanceled}
                onConfirm={onConfirmed}
              />
            )}

            {getMode() !== 'add' && (
              <>
                <SubMenuOrder tab={currentTab} updateTab={updateCurrentTab} />
                {currentTab === 'general' && (
                  <GeneralOrder
                    id={id}
                    mode={getMode()}
                    orderData={getOrder}
                    orderCustomer={parentCustomer}
                    orderBuildingSite={parentBuildingSite}
                    onCancel={onCanceled}
                    onConfirm={onConfirmed}
                    onReload={() => {
                      getCurrentOrder(id);
                    }}
                  />
                )}
                {currentTab === 'trip' && <TripOrder orderData={getOrder} />}=
                {currentTab === 'documents' && getOrder && (
                  <ListAdditionalDocs entityType="order" entityId={getOrder?.id ?? ''} canAddNew={true} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      goBack(data);
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      goBack('');
    }
  }
  function goBack(data: any) {
    if (getMode() === 'add' && !parentBuildingSite && data)
      history('/orders/' + data + '/update'); // todo : trouver pourquoi ceci
    else history('/orders');
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer hideBottomBar={true} isLarge={true}>
          {content}
        </PopupContainer>
      ) : (
        content
      )}
    </>
  );
});

export default DetailOrder;
