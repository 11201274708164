import Switch from 'react-switch';
import './symbolSwitch.scss';

interface SymbolSwitchProps {
  onChange: () => void;
  checked: boolean;
  leftSymbol: string;
  rightSymbol: string;
  disabled?: boolean;
}

export const SymbolSwitch = ({
  onChange,
  checked = false,
  leftSymbol,
  rightSymbol,
  disabled = false,
  ...props
}: SymbolSwitchProps) => {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      height={20}
      width={40}
      borderRadius={80}
      onColor={'#EFEFEF'} /*neutral3*/
      offColor={'#EFEFEF'}
      onHandleColor={'#2A85FF'} /*primary1*/
      offHandleColor={'#2A85FF'}
      checkedHandleIcon={<div className="onStyle">{rightSymbol}</div>}
      checkedIcon={<div className="offStyle">{leftSymbol}</div>}
      uncheckedHandleIcon={<div className="onStyle">{leftSymbol}</div>}
      uncheckedIcon={<div className="offStyle">{rightSymbol}</div>}
      disabled={disabled}
    />
  );
};
