import './plateFrame.scss';
import React from 'react';

export const PlateFrame = React.memo((props: { plate: string; description: string }) => {
  return (
    <div className="plate-container">
      <div className="plate-frame">
        <div className="plate-border-left" />
        <div className="plate-content">{props.plate}</div>
        <div className="plate-border-right" />
      </div>
      <div className="plate-description">{props.description}</div>
    </div>
  );
});
