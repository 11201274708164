import React, { useEffect, useState } from 'react';
//import { ReactComponent as SvgEye } from '../assets/svg/eye.svg';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import { useNavigate } from 'react-router-dom';
import ListOrders from '../../lists/Orders/ListOrders';

const Orders = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div className="main-container">
      <Menu selectedPage="orders" />
      <div className="right detail-container">
        <Head
          title={t('common.orders')}
          back={false}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            history('/quotations');
          }}
        />
        <ListOrders canAddNew={true} />
      </div>
    </div>
  );
});

export default Orders;
