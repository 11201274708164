import { Icon } from '../atoms/Icon';
import ReactTooltip from 'react-tooltip';
import './tooltipIcon.scss';

interface TooltipIconProps {
  icon: 'question' | 'info' | 'warning';
  message: string;
}

export const TooltipIcon = (props: TooltipIconProps) => {
  const addLineBreak = (str: string) =>
    str.split('\n').map((subStr) => {
      return (
        <>
          {subStr}
          <br />
        </>
      );
    });
  return (
    <>
      <span className="dune-label-icon" data-tip data-for="tooltip">
        <Icon icon={props.icon} style="secondary" />
      </span>
      <ReactTooltip id={'tooltip'} type="light" place="bottom" effect="solid" multiline={true}>
        <div className="tooltipText">{addLineBreak(props.message)}</div>
      </ReactTooltip>
    </>
  );
};
