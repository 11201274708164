export interface AdditionalDocType {
  id: string;
  label: string;
  description?: string;
  extensions?: string;
}

export enum DocTypes {
  DELIVERY_NOTE = 'DeliveryNote',
  INVOICE = 'Invoice',
  PLATE_PICTURE = 'PlatePicture',
  BED_PICTURE = 'BedPicture',
  DOCUMENT_PICTURE = 'DocumentPicture',
  ZONE_PLAN = 'ZonePlan',
  APP_LOG = 'AppLog',
  WAYBILL = 'Waybill',
  LOADING_TICKET = 'LoadingTicket',
  REFUSAL_PICTURE = 'RefusalPicture',
  SITE_LOGO = 'SiteLogo',
  OTHER_DOCUMENT = 'OtherDocument',
  SIGNATURE = 'Signature',
  COMMENT_PICTURE = 'CommentPicture',
  QUOTATION = 'quotation',
  REP_BUILDINGSITE_EXEMPTION = 'RepBuildingSiteExemption',
  REP_CUSTOMER_EXEMPTION = 'RepCustomerExemption',
  WASTE_REQUEST = 'WasteRequest',
}
