import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './filterMenu.module.scss';
import { Button } from '../../stories/dune/atoms/Button';

interface FilterMenuProps {
  validateClicked: () => void;
  resetClicked: () => void;
  children?: ReactNode | undefined;
}

const FilterMenu = React.memo((props: FilterMenuProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.filters}>
      <div className={styles.filtersContent}>{props.children}</div>
      <div className={styles.filtersAction}>
        <Button label={t('common.validate')} size="medium" style={'primary'} onClick={props.validateClicked} />
        <Button label={t('common.reset')} size="medium" style={'secondary'} onClick={props.resetClicked} />
      </div>
    </div>
  );
});

export default FilterMenu;
