import React, { useEffect, useState } from 'react';
import '../../../styles/table.scss';
import '../../../styles/popup.scss';
import '../../../index.scss';
import axios from 'axios';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Config from '../../../Config';
import { Product } from '@dune-manager/backend-core/dist/models';
import Select, { createFilter } from 'react-select';
import Zone, { ZoneSave } from '../../../models/Zone';

import { ReactComponent as SvgClose } from '../../../assets/svg/close.svg';
import { customStylesSelectReactPopup } from '../../../styles/custom-styles-select-react';
import { useTranslation } from 'react-i18next';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import { useNavigate } from 'react-router';

interface AddChildZoneProps {
  isUpdate?: boolean;
  childZones?: { id: string; label: string }[];
  from?: string;
  onsuccess(zoneAdded?: any): void;
  currentZone?: Zone;
  ClosePopup(ChildData?: boolean): void;
}

const AddChildZone = React.memo((props: AddChildZoneProps) => {
  interface IFormInputs {
    childZone: {
      value: string;
      label: string;
    };
    externalReference: string;
    unit: string;
    label: string;
  }

  const schema = yup.object().shape({
    childZone: yup.object().shape({
      value: yup.string().required('La zone est obligatoire'),
      label: yup.string().required('La zone est obligatoire'),
    }),
    //externalReference: yup.string().required('La description est obligatoire'),
  });

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingProducts, setisLoadingZones] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [optionsZone, setOptionsZones] = useState<{ label: string; value: string }[]>([]);
  const [selectedOptionZone, setselectedOptionZone] = useState<any>();
  const [optionspr, setOptionspr] = useState<any[]>([]);

  useEffect(() => {
    zoneOptions();
  }, [props.currentZone, props.childZones]);

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const { t } = useTranslation();
  const { addToast, addFixedToast } = useToast();

  const history = useNavigate();

  const fetchZones = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setisLoadingZones(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/zone/get';
      const url = orgid + `${baseUrl}`;

      axios
        .post(
          Config.get_API_EXTRANET_URL(url),
          { label: filterText, externalReference: filterText },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: 1,
              limit: 50,
            },
          },
        )
        .then((res) => {
          setisLoadingZones(false);
          const filteredZonesOptions: any = (res.data.content.items as Product[]).reduce(
            (acc: any, zone: { id: string; label: string }) => {
              if (
                !props.childZones?.find((childZone) => childZone.id === zone.id) &&
                zone.id !== props.currentZone?.id
              ) {
                acc.push({
                  label: `${zone.label}`,
                  value: zone.id,
                });
              }

              return acc;
            },
            [],
          );
          setZonesOptions(filteredZonesOptions);
          if (res.data.content.items.length === 1) {
            setselectedOptionZone({
              label: `${res.data.content.items[0].label}`,
              value: res.data.content.items[0].id,
            });
          }
        })
        .catch(() => {
          setisLoadingZones(false);
          setZonesOptions([]);
        });
    } else {
      setZonesOptions([]);
    }
  };

  const setZonesOptions = (products: any) => {
    const options: any[] = [];

    if (products != null) {
      products.forEach((s: any) => {
        const option = {
          value: s.value,
          label: s.label,
        };

        options.push(option);
      });

      setOptionsZones(options);
    }
  };

  const updateChildZone = (item: any) => {
    setValue('childZone', item);
    setselectedOptionZone(item);
    setErrorMessage('');
  };

  const onSubmit = () => {
    onSubmitCreate();
    return;
  };

  const onClosePopup = () => {
    props.ClosePopup();
  };

  const onSubmitCreate = async () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const baseUrl = '/zone/edit/' + props.currentZone?.id;
    const url = orgid + `${baseUrl}`;

    const currentChildZones = props.currentZone?.childZones?.map((z) => z.id) ?? [];

    const dataToSend: ZoneSave = {
      ...props.currentZone,
      products: props.currentZone?.products?.map((p) => p.id ?? '') ?? [],
      childZonesId: [...currentChildZones, selectedOptionZone?.value],
    };

    axios
      .post(Config.get_API_EXTRANET_URL(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        props.onsuccess({
          id: selectedOptionZone?.value,
          label: selectedOptionZone?.label,
        });
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  const zoneOptions = () => {
    const optionspr: any[] = [];

    // Hide add product option
    //optionspr.push({ tabLabel: 'Produit existant' }, { tabLabel: 'Nouveau produit' });
    optionspr.push({ tabLabel: 'Produit existant' });

    setOptionspr(optionspr);
  };

  const deleteSelectedOption = () => {
    setselectedOptionZone({});
  };

  return (
    <div className="popup">
      <div className="popup__header">
        <div>
          <p className="title1SemiBold">{t('common.addChildZone')}</p>
        </div>
        <SvgClose className="title__svg__close" onClick={onClosePopup}></SvgClose>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="popup-col">
            {/* <div className="input__title"></div> */}

            {props.childZones?.map((childZone) => {
              return (
                <div className="input__title">
                  <div style={{ marginLeft: 20 }}>{childZone.label}</div>
                </div>
              );
            })}

            <Select
              {...register('childZone')}
              className="popup__select"
              isMulti={false}
              isLoading={isLoadingProducts}
              options={optionsZone}
              loadingMessage={() => 'Chargement en cours'}
              menuPlacement={'auto'}
              menuPosition={'fixed'}
              noOptionsMessage={() =>
                filterText.length < 1 ? 'Entrez 1 caractère pour lancer une recherche' : 'Pas de résultat'
              }
              styles={customStylesSelectReactPopup('#F4F4F4')}
              placeholder={`Choisir une zone`}
              onFocus={() => deleteSelectedOption()}
              onInputChange={(e) => fetchZones(e)}
              isSearchable={true}
              onChange={(input) => {
                // if (input != null && input.value != null) {
                updateChildZone(input);
                // }
              }}
              value={selectedOptionZone}
              filterOption={createFilter(filterConfig)}
            />

            <div style={{ color: 'black' }}> {selectedOptionZone?.id} </div>

            <p className="validator-error">{errors.childZone?.label?.message ?? errors.childZone?.value?.message}</p>
          </div>
        </>

        <div className="popup-row-right">
          <div className="addBtn" onClick={handleSubmit(onSubmit)}>
            <div className="base2">Ajouter</div>
          </div>
          <div className="cancelBtn" onClick={onClosePopup}>
            <div className="base2">Annuler</div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default AddChildZone;
