import { TripStatus } from '@dune-manager/backend-core/dist/models/trip';
import { ZoneType } from '@dune-manager/backend-core/dist/models/zone';
import React, { useEffect, useState } from 'react';
import './TabFilter.scss';
import { TypeZoneTranslate } from '../../models';

export interface TabFilterProps {
  tabArray: {
    tabLabel: string;
    tabLabelTranslate: string;
  }[];
  parent: string;
  filterSet: (val: any) => void;
  defaultTab: string;
  overrideTab?: string;
}

const TabFilter = React.memo((props: TabFilterProps) => {
  const [tabSelected, setTabSelected] = useState(props.defaultTab ?? 'Tous');

  let filtre: any;

  const onSendOptions = (option: string) => {
    switch (props.parent) {
      // Voyages
      case 'Trip':
        localStorage.setItem('defaultTabTrip', option);
        if (option == 'Tous') filtre = null;

        if (option == 'En attente')
          filtre = [
            TripStatus.WAITING_FOR_SPACE_ENTRY,
            TripStatus.WAITING_FOR_MANAGER_ENTRY,
            TripStatus.WAITING_FOR_ERP_ENTRY,
            TripStatus.WAITING_FOR_ERP_PROCESSING,
            TripStatus.WAITING_FOR_SPACE_PROCESSING,
            TripStatus.WAITING_FOR_SPACE_EXIT,
            TripStatus.WAITING_FOR_ERP_EXIT,
            TripStatus.WAITING_FOR_MANAGER_EXIT,
            TripStatus.ASKING_FOR_EXIT,
          ];

        if (option == 'En cours')
          filtre = [
            TripStatus.CREATED,
            TripStatus.PLANNED,
            TripStatus.STARTED,
            TripStatus.WAITING_FOR_SPACE_ENTRY,
            TripStatus.WAITING_FOR_EQUIPMENT_ENTRY,
            TripStatus.WAITING_FOR_MANAGER_ENTRY,
            TripStatus.WAITING_FOR_ERP_ENTRY,
            TripStatus.WAITING_FOR_DRIVER_ENTRY,
            TripStatus.ON_SITE,
            TripStatus.WAITING_FOR_SPACE_PROCESSING,
            TripStatus.WAITING_FOR_EQUIPMENT_PROCESSING,
            TripStatus.WAITING_FOR_ERP_PROCESSING,
            //TripStatus.WaitingForManagerProcessing,
            TripStatus.WAITING_FOR_APP_PROCESSING,
            TripStatus.PROCESSED,
            TripStatus.PROCESSING,
            TripStatus.WAITING_FOR_SPACE_EXIT,
            TripStatus.WAITING_FOR_EQUIPMENT_EXIT,
            TripStatus.WAITING_FOR_ERP_EXIT,
            TripStatus.WAITING_FOR_DRIVER_EXIT,
            TripStatus.WAITING_FOR_MANAGER_EXIT,
            TripStatus.CAN_EXIT,
            TripStatus.ASKING_FOR_EXIT,
            TripStatus.REFUSED,
            TripStatus.ANOMALY,
            TripStatus.CAN_ENTER,
            TripStatus.GO_TO_RECEPTION,
          ];

        if (option == 'Terminé') filtre = [TripStatus.EXITED, TripStatus.CANCELED, TripStatus.DELETED];

        if (option == 'Refusé') filtre = [TripStatus.CANCELED, TripStatus.DELETED];

        if (option == 'Parti') filtre = [TripStatus.EXITED];
        break;

      // Zone
      case 'Zone':
        if (option == 'Tous') filtre = null;
        if (option == TypeZoneTranslate.process) filtre = ZoneType.PROCESS;
        if (option == TypeZoneTranslate.waiting) filtre = ZoneType.WAITING;
        if (option == TypeZoneTranslate.transit) filtre = ZoneType.TRANSIT;

        break;

      // Alert
      case 'Alert':
        if (option == 'Tous') filtre = null;
        if (option == 'Validé') filtre = true;
        break;

      // Autorisations d'acces
      case 'AuthorizedV':
        if (option == 'Tous') filtre = null;
        if (option == 'En cours') filtre = true;
        break;

      default:
        if (option === 'Tous') filtre = null;
        else filtre = option.split(';');
        break;
    }
    setTabSelected(option);
    props.filterSet(filtre);
  };

  useEffect(() => {
    if (props.overrideTab) onSendOptions(props.overrideTab);
  }, [props.overrideTab]);

  return (
    <div className="tabFilter">
      <div className="tabFilter__tab">
        {props.tabArray.map((tab, index) => {
          return (
            <div className={tabSelected === tab.tabLabel ? 'tab_active' : 'tab'} key={index}>
              <div className="base2" onClick={() => onSendOptions(tab.tabLabel)}>
                {tab.tabLabelTranslate}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default TabFilter;
