import React, { ReactComponentElement } from 'react';
import { Icon, IconType } from './Icon';
import './button.scss';

interface ButtonProps {
  style?: 'primary' | 'primary-02' | 'primary-03' | 'primary-04' | 'secondary' | 'tertiary' | 'quaternary' | 'white';

  size?: 'small' | 'medium' | 'large' | 'wide' | 'widelarge' | 'thin' | 'widethin';

  radius?: 'prefix' | 'suffix';

  dropdown?: boolean;

  listDropdown?: any[];

  label?: string;

  hoverDescription?: string;

  onClick?: () => void;

  iconRight?: IconType;

  iconLeft?: IconType;

  disabled?: boolean;
}

export const Button = ({
  size = 'medium',
  dropdown = false,
  style = 'primary',
  radius = undefined,
  iconRight = '',
  iconLeft = '',
  label,
  hoverDescription,
  listDropdown = [],
  disabled = false,
  onClick,
  ...props
}: ButtonProps) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    if (!disabled) {
      if (dropdown) handleOpen();
      else if (onClick) onClick();
    }
  };

  return (
    <div
      className={[
        'base2',
        'dune-button',
        `dune-button--${size}`,
        radius ? `dune-button--${radius}` : '',
        dropdown ? 'dropdown' : '',
        `dune-button--${disabled ? 'disabled' : style}`,
      ].join(' ')}
      onClick={handleClick}
      title={hoverDescription}
    >
      <button type="button">
        {iconLeft == '' ? '' : <Icon icon={iconLeft} />}
        {label ? <span className="dune-button-label">{label}</span> : ''}
        {iconRight == '' ? '' : <Icon icon={iconRight} />}
      </button>

      {open ? (
        <ul className="menu-dropdown">
          {listDropdown.map((menuItem, index) => (
            <li key={index} className="menu-item">
              {menuItem}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
