import { SelectData, SelectDataOption, SelectProps, StyledOption } from '../../stories/dune/molecules/SelectData';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationStatus } from '../../models/Quotation';
import { InvoiceStatus } from '../../models/Invoice';
import { WasteRequestStatus } from '../../models/WasteRequest';
import { TripStatus } from '../../models/Trip';

export type StatusType = 'quotation' | 'order' | 'trip' | 'invoice' | 'wasteRequest';

interface SelectStatusProps extends SelectProps {
  statusType: StatusType;
}

export const SelectStatus = ({
  statusType,
  setValue,
  register,
  registerName = '',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectStatusProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const selectGroup1: string[] = [];
  const selectGroup2: string[] = [];
  const selectGroup3: string[] = [];

  if (statusType === 'trip') {
    selectGroup1.push(TripStatus.PLANNED);
    selectGroup1.push(TripStatus.STARTED);

    selectGroup2.push(TripStatus.CAN_ENTER);
    selectGroup2.push(TripStatus.WAITING_FOR_APP_PROCESSING);
    selectGroup2.push(TripStatus.PROCESSING);
    selectGroup2.push(TripStatus.PROCESSED);
    selectGroup2.push(TripStatus.ANOMALY);
    selectGroup2.push(TripStatus.WAITING_FOR_MANAGER_EXIT);
    selectGroup2.push(TripStatus.REFUSED);

    selectGroup3.push(TripStatus.GO_TO_RECEPTION);
    selectGroup3.push(TripStatus.CAN_EXIT);
    selectGroup3.push(TripStatus.CANCELED);
    selectGroup3.push(TripStatus.DELETED);
    selectGroup3.push(TripStatus.EXITED);
  }

  if (statusType === 'quotation') {
    selectGroup1.push(QuotationStatus.CREATED);
    selectGroup1.push(QuotationStatus.UPDATED);
    selectGroup1.push(QuotationStatus.IN_ERROR);

    selectGroup2.push(QuotationStatus.SENT);
    selectGroup2.push(QuotationStatus.GENERATED);

    selectGroup3.push(QuotationStatus.VALIDATED);
    selectGroup3.push(QuotationStatus.REFUSED);
  }

  if (statusType === 'invoice') {
    selectGroup1.push(InvoiceStatus.CREATED);
    selectGroup1.push(InvoiceStatus.ISSUED);

    selectGroup2.push(InvoiceStatus.UPCOMING);

    selectGroup3.push(InvoiceStatus.PAID);
    selectGroup3.push(InvoiceStatus.ARCHIVED);
  }

  if (statusType === 'wasteRequest') {
    selectGroup1.push(WasteRequestStatus.TO_BE_COMPLETED);

    selectGroup2.push(WasteRequestStatus.REQUESTED);

    selectGroup3.push(WasteRequestStatus.ACCEPTED);
    selectGroup3.push(WasteRequestStatus.REFUSED);
  }

  const options: SelectDataOption[] = [...selectGroup1, ...selectGroup2, ...selectGroup3].map((x) => {
    return {
      label: t('status.' + x),
      value: x,
    };
  });

  const styledOptions: StyledOption[] = [
    { optionValues: selectGroup1, styleColor: '#FDE8DF' },
    { optionValues: selectGroup2, styleColor: '#DDEBFF' },
    { optionValues: selectGroup3, styleColor: '#E6F5E6' },
  ];

  return (
    <SelectData
      title={t('common.status')}
      placeholder={t('common.statusChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={{
        label: t('status.' + initialOption),
        value: initialOption,
      }}
      forceSelectedOption={{
        label: t('status.' + forceSelectedOption),
        value: forceSelectedOption,
      }}
      filterText={''}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      styledOptions={styledOptions}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};
