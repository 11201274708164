import './WasteRequests.scss';
import React from 'react';
import ListWasteRequests from '../../lists/WasteRequests/ListWasteRequests';
import { Head } from '../../../stories/dune/molecules/Head';
import { useTranslation } from 'react-i18next';

import Menu from '../../Menu';
import { useNavigate } from 'react-router-dom';
import { WasteRequestStatus } from '../../../models/WasteRequest';

const WasteRequests = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div className="main-container">
      <Menu selectedPage="wasteRequests"></Menu> {/*TODO : sortir et mettre dans classe 'parent'*/}
      <div className="right detail-container">
        <Head
          title={t('common.wasteAcceptationRequestsAndCertificates')}
          back={false}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            history('/wasteRequests');
          }}
        />
        <ListWasteRequests defaultStatus={WasteRequestStatus.REQUESTED} />
      </div>
    </div>
  );
});

export default WasteRequests;
