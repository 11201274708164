import React, { useEffect, useState } from 'react';
import './Account.scss';
import '../styles/table.scss';
import '../styles/popup.scss';
import axios from 'axios';
import Menu from './Menu';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ToastComponent from './ToastComponent';
import Toast from '../models/Toast';
import { ToastTypes } from '../models/ToastTypes';
import Config from '../Config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Account = React.memo(() => {
  const { t } = useTranslation();
  const history = useNavigate();

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [toasts, setToast] = useState<Toast[]>([]);
  const token = localStorage.getItem('token');

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  interface IFormInputs {
    firstname: string;
    lastname: string;
    password: string;
    confirmPassword: string;
  }

  const regExPassword = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Le password est obligatoire')
      .matches(regExPassword, '6-20 Caractères, une majuscule, une minuscule, un chiffre'),
    confirmPassword: yup
      .string()
      .required('Le password est obligatoire')
      .oneOf([yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const updateFirstname = (firstname: string) => {
    setValue('firstname', firstname);
    setFirstname(firstname);
    setErrorMessage('');
  };

  const updateLastname = (lastname: string) => {
    setValue('lastname', lastname);
    setLastname(lastname);
    setErrorMessage('');
  };

  const updatePassword = (password: string) => {
    setValue('password', password);
    setNewPassword(password);
    setErrorMessage('');
  };

  const updateConfirmPassword = (confirmPassword: string) => {
    setValue('confirmPassword', confirmPassword);
    setConfirmPassword(confirmPassword);
    setErrorMessage('');
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, '', type)]);
  };

  const onSubmitPassword = () => {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const dataToSend = {
      newPassword: window.btoa(newPassword),
      passwordConfirmation: window.btoa(confirmPassword),
    };

    axios
      .post(Config.get_API_URL() + 'auth/change-password', dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        addToast(t('common.passwordUpdated'), ToastTypes.success);
        setConfirmPassword('');
        setNewPassword('');
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  useEffect(() => {
    const url = Config.get_API_URL() + 'auth/account';
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsAddingLoading(false);
        setLastname(res.data.content.lastname);
        setFirstname(res.data.content.firstname);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }, []);

  return (
    <div className="updatePassword">
      <Menu selectedPage="account"></Menu>
      <ToastComponent toastList={toasts} position={'top-right'} autoDelete={true} dismissTime={4000} key={1} />

      <div className={'right detail-container'}>
        <h3 className="title">Mon compte</h3>
        <div className="label">Nom</div>
        <input
          readOnly={true}
          type="text"
          name="lastname"
          value={lastname}
          placeholder="Nom"
          onChange={(e) => updateLastname(e?.target.value.toString())}
        />
        <p className="validator-error">{errors.lastname?.message}</p>

        <div className="label">Prénom</div>
        <input
          readOnly={true}
          type="text"
          name="firstname"
          value={firstname}
          placeholder="Prénom"
          onChange={(e) => updateFirstname(e?.target.value.toString())}
        />
        <p className="validator-error">{errors.firstname?.message}</p>

        <div>
          <ToastComponent toastList={toasts} position={'top-right'} autoDelete={true} dismissTime={4000} key={1} />

          <h3 className="title">Modifier le mot de passe</h3>

          <form onSubmit={handleSubmit(onSubmitPassword)}>
            <div className="label">Nouveau Mot de passe</div>
            <input
              type="password"
              name="newPassword"
              value={newPassword}
              placeholder="Nouveau Mot de passe"
              onChange={(e) => updatePassword(e?.target.value.toString())}
            />
            <p className="validator-error">{errors.password?.message}</p>

            <div className="label">Confirmation du mot de passe</div>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              placeholder="Confirmation du mot de passe"
              onChange={(e) => updateConfirmPassword(e?.target.value.toString())}
            />
            <p className="validator-error">{errors.confirmPassword?.message}</p>

            <div>{errorMessage}</div>
            <div>
              <div className="button addBtn" onClick={handleSubmit(onSubmitPassword)}>
                <div className="base2">{!isAddingLoading ? 'Modifier' : 'Modification ...'}</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default Account;
