import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralQuotation from './GeneralQuotation';
import HistQuotation from './HistQuotation';
import SubMenuQuotation from './SubMenuQuotation';
import { GetQuotation } from '../../../models/Quotation';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import { useDispatch, useSelector } from 'react-redux';
import { DuplicationState, duplicationActions } from '../../../store/duplication-slice';

interface DetailQuotationProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailQuotation = React.memo((props: DetailQuotationProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  const dispatch = useDispatch();
  const quotationDuplicateData = useSelector((state: { duplication: DuplicationState }) => state.duplication.quotation);

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
    quotationMode: 'customer' | 'buildingSite';
  };

  const { id, mode, quotationMode: quotationMode } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getQuotation, setGetQuotation] = useState<GetQuotation>();

  function getCurrentQuotation(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/quotation/detail/';
      const url = Config.get_API_EXTRANET_URL(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetQuotation
            setGetQuotation(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else if (quotationDuplicateData && getMode() === 'add') {
      setGetQuotation(quotationDuplicateData);
      dispatch(duplicationActions.clearDuplicatedData('Quotation'));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCurrentQuotation(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <div className="main-container">
      <Menu selectedPage="quotations"></Menu> {/*TODO : sortir et mettre dans classe 'parent'*/}
      <div className="right detail-container">
        <Head
          title={
            getMode() === 'add'
              ? t('common.newQuotation')
              : t('common.quotation') +
                ' ' +
                (!getQuotation
                  ? ''
                  : getQuotation.quotationNumber +
                    (getQuotation.externalReference ? '-' + getQuotation.externalReference : '') +
                    (' - ' + t('status.' + getQuotation.status)))
          }
          back={true}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            history('/quotations');
          }}
        />

        {!isLoading && (
          <>
            {getMode() === 'add' && (
              <GeneralQuotation
                mode={getMode()}
                quotationMode={quotationMode}
                quotationType="Quotation"
                quotationData={getQuotation}
                onCancel={onCanceled}
                onConfirm={onConfirmed}
              />
            )}

            {getMode() !== 'add' && (
              <>
                <SubMenuQuotation tab={currentTab} currentTab={updateCurrentTab} />
                {currentTab === 'general' && (
                  <GeneralQuotation
                    id={id}
                    mode={getMode()}
                    quotationMode={quotationMode}
                    quotationType="Quotation"
                    quotationData={getQuotation}
                    onCancel={onCanceled}
                    onConfirm={onConfirmed}
                    onReload={() => {
                      getCurrentQuotation(id);
                    }}
                  />
                )}
                {currentTab === 'history' && (
                  <div className="detail-content">
                    <HistQuotation quotationData={getQuotation} />
                  </div>
                )}
                {currentTab === 'documents' && getQuotation && (
                  <ListAdditionalDocs entityType="quotation" entityId={getQuotation.id} canAddNew={true} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      if (getMode() === 'add') history('/quotations/' + data + '/update');
      else history('/quotations');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/quotations');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer hideBottomBar={true} isLarge={true}>
          {content}
        </PopupContainer>
      ) : (
        content
      )}
    </>
  );
});

export default DetailQuotation;
