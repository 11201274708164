import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './ToastComponent.scss';

const ToastComponent = (props: any) => {
  const { toastList, position, autoDelete, dismissTime } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);

    // eslint-disable-next-line
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [toastList, autoDelete, dismissTime, list]);

  const deleteToast = (id: any) => {
    const listItemIndex = list.findIndex((e: any) => e.id === id);
    const toastListItem = toastList.findIndex((e: any) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  return (
    <>
      <div className={`notification-container ${position}`}>
        {list.map((toast: any, i: any) => (
          <div key={i} className={`notification toast ${position}`} style={{ backgroundColor: toast.backgroundColor }}>
            <p className="notification-title">{toast.title}</p>
            <p className="notification-message">{toast.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

ToastComponent.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

export default ToastComponent;
