import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ReactComponent as SvgCapteur } from '../assets/svg/capteur-presence.svg';
import { ReactComponent as SvgOpenCurtain } from '../assets/svg/volet-ouvert.svg';
import { ReactComponent as SvgClosedCurtain } from '../assets/svg/volet-ferme.svg';
import { ReactComponent as SvgCameraDevice } from '../assets/svg/camera-device.svg';
import { ReactComponent as SvgTrafficLightsBoth } from '../assets/svg/double-feux.svg';
import { ReactComponent as SvgTrafficLightsGreen } from '../assets/svg/feux-vert.svg';
import { ReactComponent as SvgTrafficLightsRed } from '../assets/svg/feux-rouge.svg';
import { ReactComponent as SvgTrafficLightOff } from '../assets/svg/feux-eteint.svg';
import { ReactComponent as SvgOpenBarrier } from '../assets/svg/barriere-ouvert.svg';
import { ReactComponent as SvgClosedBarrier } from '../assets/svg/barriere-ferme.svg';
import { ReactComponent as SvgPrinter } from '../assets/svg/printer.svg';
import { ReactComponent as SvgWeighBridge } from '../assets/svg/weighbridge-truck.svg';
import './ControllableDevices.scss';
import moment from 'moment-timezone';
import axios from 'axios';
import Config from '../Config';
import { useTranslation } from 'react-i18next';
import { Button } from '../stories/dune/atoms/Button';
import { Colors } from '../utils/Colors';
import { PlateFrame } from '../stories/dune/molecules/PlateFrame';
import { GlobalParameter } from '../models/GlobalParameter';

export const BarrierDevice = React.memo((props: any) => {
  const { t } = useTranslation();
  const status_sensor = props.data['STATUSSENSOR'];
  const force_open = props.data['FORCEOPEN'];
  const presence_sensor = props.data['PRESENCESENSOR'];
  const status = status_sensor?.lastReceivedData;

  const [forcedOpen, setForcedOpen] = useState(force_open?.lastReceivedData?.turnOn ?? false);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  let presence = {
    presenceDetected: 'Unknown',
  };
  if (presence_sensor?.lastReceivedData) presence = presence_sensor.lastReceivedData;
  let display_presence = t('equipments.noPresence');
  if (presence.presenceDetected !== undefined) {
    if (presence.presenceDetected) display_presence = t('equipments.presence');
    else display_presence = t('equipments.noPresence');
  }

  const handleChange = (param: boolean) => {
    send_command(force_open?.id, props.terminalId, [{ turnOn: param }]);
    setForcedOpen(param);
    // get_status();
    return null;
  };
  return (
    <div className={'controllableDevice'}>
      <div
        className={'tabHeaderTitle title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {force_open?.equipementGroup}
        {status?.isOpen ? (
          <SvgOpenBarrier style={{ width: '250px', height: '120px' }} />
        ) : (
          <SvgClosedBarrier style={{ width: '250px', height: '120px' }} />
        )}
        <div
          className="title1SemiBold"
          style={{
            color: status?.isOpen ? Colors.green03 : Colors.red,
          }}
        >
          {t(status?.isOpen ? 'equipments.isOpenned' : 'equipments.isClosed')}
        </div>
      </div>

      <div className="row space-between align-center" style={{ marginTop: 4, width: '100%' }}>
        <SvgCapteur
          height={50}
          width={50}
          fill={presence.presenceDetected ? Colors.green03 : Colors.red}
          stroke={presence.presenceDetected ? Colors.green03 : Colors.red}
        />
        <div
          style={{
            textAlign: 'center',
            color: presence.presenceDetected ? Colors.green03 : Colors.red,
          }}
        >
          {display_presence}
        </div>
      </div>
      <div className="row space-between align-center" title={t('equipments.forceOpenDesc')}>
        <div className="title1SemiBold" style={{ marginRight: 5 }}>
          {t('equipments.forceOpen')}
        </div>
        <Switch
          onChange={handleChange}
          checked={forcedOpen ?? false}
          onColor={'#2a85ff'}
          disabled={force_open === undefined}
        />
      </div>
    </div>
  );
});

export const Curtain = React.memo((props: any) => {
  const { t } = useTranslation();
  const status_sensor = props.data['STATUSSENSOR'];
  const force_open = props.data['OPENORCLOSE'];
  const presence_sensor = props.data['PRESENCESENSOR'];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [props.data['STATUSSENSOR']]);

  let status = {
    isOpen: force_open?.las,
  };
  if (status_sensor && status_sensor.lastReceivedData) status = status_sensor.lastReceivedData;
  let presence = {
    presenceDetected: 'Unknown',
  };

  if (presence_sensor && presence_sensor.lastReceivedData) presence = presence_sensor.lastReceivedData;
  let display;
  if (status.isOpen) {
    display = <SvgOpenCurtain style={{ width: '250px', height: '100px' }} />;
  } else {
    display = <SvgClosedCurtain style={{ width: '250px', height: '100px' }} />;
  }
  const handleChange = (param: boolean) => {
    send_command(force_open.id, props.terminalId, [{ open: param }]);
    setIsLoading(true);
    return null;
  };
  return (
    <div className={'controllableDevice space-between'}>
      <div
        className={'title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {force_open?.equipementGroup}
      </div>

      <div>{display}</div>
      <div title={t('equipments.curtain.openOrClose')}>
        <Switch
          onChange={handleChange}
          checked={status.isOpen}
          onColor={'#2a85ff'}
          disabled={force_open === undefined || isLoading}
        />
      </div>

      <div
        className={'title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: 50,
          color: status.isOpen ? Colors.green03 : Colors.red,
        }}
      >
        {status.isOpen ? t('equipments.curtain.isOpen') : t('equipments.curtain.isClosed')}
      </div>
    </div>
  );
});

export const Weighbridge = React.memo((props: any) => {
  const { t } = useTranslation();
  const bridge = props.data['GETWDSD'];

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);
  // console.log(bridge);
  let state = {
    dsdValue: null,
    status: null,
    weighingDate: new Date(0),
    weightValue: null,
  };
  if (bridge?.lastReceivedData) {
    state = bridge.lastReceivedData;
    state.weighingDate = new Date(state.weighingDate);
  }

  return (
    <div className={'controllableDevice space-between'}>
      <div
        className={'tabHeaderTitle title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '2px',
        }}
      >
        {bridge?.equipementGroup}
      </div>
      <SvgWeighBridge height={70} />
      <div
        className={'tabHeaderTitle title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '2px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {bridge?.lastReceivedData ? 'DSD : ' + bridge.lastReceivedData.dsdValue : ''}
        <div />
        {bridge?.lastReceivedData ? 'Status : ' + bridge.lastReceivedData.status : ''}
        <div />
        {bridge?.lastReceivedData
          ? 'Date : ' + moment(bridge.lastReceivedData.weighingDate).tz(siteTimeZone).format('HH:mm')
          : ''}
        <div />
        {bridge?.lastReceivedData ? bridge.lastReceivedData.weightValue + ' Kg' : ''}
        <div />

        <Button
          label={t('common.dsd_weight')}
          style="primary"
          onClick={() => send_command(bridge.id, props.terminalId, [])}
        />
      </div>
    </div>
  );
});

export const TrafficLight = React.memo((props: any) => {
  const { t } = useTranslation();
  const green = props.data['GREEN'];
  const red = props.data['RED'];
  const [isGreenOn, setIsGreenOn] = useState(green?.lastReceivedData?.isOn);
  const [isRedOn, setIsRedOn] = useState(red?.lastReceivedData?.isOn);
  if (isGreenOn !== green?.lastReceivedData?.isOn) setIsGreenOn(green?.lastReceivedData?.isOn);
  if (isRedOn !== red?.lastReceivedData?.isOn) setIsRedOn(red?.lastReceivedData?.isOn);
  let image_source = <div />;
  if (isRedOn && isGreenOn) {
    image_source = <SvgTrafficLightsBoth style={{ width: '100px', height: '100px' }} />;
  }
  if (isRedOn && !isGreenOn) {
    image_source = <SvgTrafficLightsRed style={{ width: '100px', height: '100px' }} />;
  }
  if (!isRedOn && isGreenOn) {
    image_source = <SvgTrafficLightsGreen style={{ width: '100px', height: '100px' }} />;
  }
  if (!isRedOn && !isGreenOn) {
    image_source = <SvgTrafficLightOff style={{ width: '100px', height: '100px' }} />;
  }

  return (
    <div className={'controllableDevice space-between'}>
      <div
        className={'title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '5px',
        }}
      >
        {green?.equipementGroup}
      </div>
      {image_source}
      <div style={{ width: '100%' }}>
        <div
          className={'title1SemiBold row space-between'}
          style={{
            textAlign: 'center',
            marginBottom: 10,
            width: '100%',
          }}
        >
          <div>{t('common.green_light')}</div>
          <div className="row align-center">
            <Switch
              onChange={() => {
                send_command(green?.id, props.terminalId, [{ turnOn: !isGreenOn }]);
                // setIsGreenOn(!isGreenOn);
                // sleep(5000).then(() => get_status());
              }}
              checked={isGreenOn}
              onColor={'#2a85ff'}
            />
            <div style={{ marginLeft: 5 }}>{t(isGreenOn ? 'equipments.on' : 'equipments.off')}</div>
          </div>
        </div>

        <div
          className={'title1SemiBold row space-between'}
          style={{
            textAlign: 'center',
            marginBottom: '5px',
            width: '100%',
          }}
        >
          <div>{t('common.red_light')}</div>
          <div className="row align-center">
            <Switch
              onChange={() => {
                send_command(red?.id, props.terminalId, [{ turnOn: !isRedOn }]);
                // setIsGreenOn(!isGreenOn);
                // sleep(5000).then(() => get_status());
              }}
              checked={isRedOn}
              onColor={'#2a85ff'}
            />
            <div style={{ marginLeft: 5 }}>{t(isRedOn ? 'equipments.on' : 'equipments.off')}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export const LapiCamera = React.memo((props: any) => {
  const { t } = useTranslation();

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);
  const last_read = props.data['LASTREAD'];
  const received_data = last_read?.lastReceivedData;
  return (
    <div className={'controllableDevice'}>
      <div
        className={'tabHeaderTitle title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {last_read.equipementGroup}
      </div>
      <SvgCameraDevice style={{ height: '70px', width: '150px', objectFit: 'contain' }} />
      <div
        className={'tabHeaderTitle title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        <PlateFrame
          plate={received_data?.plateRead}
          description={
            received_data?.readDate && moment(received_data.readDate).isValid()
              ? moment(received_data.readDate).tz(siteTimeZone).format('DD/MM/YY HH:mm:ss')
              : ''
          }
        />
        <img
          style={{ height: '75px', width: '100%', marginTop: '10px', objectFit: 'contain', cursor: 'pointer' }}
          src={`data:image/jpeg;base64,${received_data.plateReadPicture}`}
          onClick={() => {
            const newWindow = window.open();
            if (newWindow) {
              newWindow.document.write(
                `<head><title>${t('common.last_read_plate')}</title><link rel="icon" href="data:,"></head>
                <body><img src="data:image/jpeg;base64,${received_data.plateReadPicture}" /></body>`,
              );
              newWindow.document.close();
            }
          }}
        />

        <Button
          label={t('common.last_read_plate')}
          style="primary"
          onClick={() => {
            send_command(last_read.id, props.terminalId, [{ getPicture: true }]);
            setTimeout(
              function () {
                if (props.onReload) props.onReload();
              }.bind(this),
              3000,
            );
          }}
        />
      </div>
    </div>
  );
});

export const SkipCamera = React.memo((props: any) => {
  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);
  const skip_camera = props.data['TRUCKBEDCAMERA'];
  const received_data = skip_camera.lastReceivedData;
  const date =
    received_data?.pictureDate && moment(received_data.pictureDate).isValid()
      ? moment(received_data.pictureDate).tz(siteTimeZone).format('DD/MM/YY HH:mm:ss')
      : '';
  const { t } = useTranslation();
  return (
    <div className={'controllableDevice'}>
      <div
        className={'tabHeaderTitle title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {skip_camera.equipementGroup}
      </div>
      <SvgCameraDevice height={70} />
      <div
        className={'tabHeaderTitle title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {received_data?.picture ? (
          <img
            style={{ height: '75px', width: '100%', objectFit: 'contain', cursor: 'pointer' }}
            src={`data:image/jpeg;base64,${received_data?.picture}`}
            onClick={() => {
              const newWindow = window.open();
              if (newWindow) {
                newWindow.document.write(
                  `<head><title>${
                    skip_camera.equipementGroup + ' - ' + date
                  }</title><link rel="icon" href="data:,"></head>
                  <body><img src="data:image/jpeg;base64,${received_data.picture}" /></body>`,
                );
                newWindow.document.close();
              }
            }}
          />
        ) : (
          ''
        )}

        {received_data?.pictureDate ? <div>{date}</div> : ''}

        <div style={{ marginTop: 20 }}>
          <Button
            label={t('common.takePicture')}
            style="primary"
            onClick={() => {
              send_command(skip_camera.id, props.terminalId, []);
              setTimeout(
                function () {
                  if (props.onReload) props.onReload();
                }.bind(this),
                3000,
              );
            }}
          />
        </div>
      </div>
    </div>
  );
});

export const TerminalSensor = React.memo((props: { data: any }) => {
  const { t } = useTranslation();

  let presence = {
    presenceDetected: false,
  };
  if (props.data.lastReceivedData) presence = props.data.lastReceivedData;
  let color = Colors.red;
  let display_presence = t('equipments.noPresence');
  if (presence.presenceDetected) {
    color = Colors.green03;
    display_presence = t('equipments.presence');
  }
  return (
    <div className={'controllableDevice'}>
      <div
        className={'title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: 50,
        }}
      >
        {props.data.label}
      </div>
      <div className="title1SemiBold">
        <SvgCapteur fill={color} stroke={color} />
        <div
          style={{
            textAlign: 'center',
            color: presence.presenceDetected ? Colors.green03 : Colors.red,
          }}
        >
          {display_presence}
        </div>
      </div>
    </div>
  );
});

export const Printer = React.memo((props: { data: any; terminalId: string }) => {
  const { t } = useTranslation();

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);
  const info = props.data['GETINFO'];
  let status = {
    lastPrintDate: 'Unknown',
    lastPrintedDoc: 'Unknown',
    status: 'Unknown',
  };
  // get_status(info);
  if (info?.lastReceivedData) status = info.lastReceivedData;
  let info_display = <div />;
  if (info?.lastReceivedData) {
    const statusLines = status.status.split(';').map((x) => {
      return (
        <>
          {x}
          <br />
        </>
      );
    });
    info_display = (
      <div>
        <div />
        {'Last print : ' +
          (moment(status.lastPrintDate).isValid()
            ? moment(status.lastPrintDate).tz(siteTimeZone).format('DD/MM/YY HH:mm:ss')
            : '')}
        <div />
        {status.lastPrintedDoc}
        <div />
        {statusLines}
        <div />
      </div>
    );
  }
  return (
    <div className={'controllableDevice space-between'}>
      <div
        className={'title1SemiBold'}
        style={{
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {info?.label}
      </div>
      <SvgPrinter width={'100px'} />
      {info_display}
      <Button
        label={t('equipments.information')}
        style="primary"
        onClick={() => send_command(info.id, props.terminalId, [])}
      />

      {/* <button className="getInfoButton" onClick={() => send_command(info.id, props.terminalId, [])}>
          {'Informations'}
        </button> */}
    </div>
  );
});

function get_status() {
  const token = localStorage.getItem('token');
  const orgid = localStorage.getItem('orgid');
  const url = `${orgid}/terminal/equipments-status-request`;
  axios
    .get(Config.get_API_EXTRANET_URL(url), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      console.log(res);
    });
}

function send_command(equipmentId: string, terminalId: string, command: any) {
  const token = localStorage.getItem('token');
  const orgid = localStorage.getItem('orgid');
  const url = `${orgid}/terminal/send-command-parameter`;
  const data_post = {
    terminalId: terminalId,
    equipmentId: equipmentId,
    commandParameters: command,
  };
  axios
    .post(Config.get_API_EXTRANET_URL(url), data_post, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      console.log(res);
    });
}
