import React, { useEffect, useState } from 'react';
//import { ReactComponent as SvgEye } from '../assets/svg/eye.svg';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import { useNavigate } from 'react-router-dom';
import ListCustomers from '../../lists/Customers/ListCustomers';

const Customers = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div className="main-container">
      <Menu selectedPage="customers" />
      <div className="right detail-container">
        <Head
          title={t('common.customers')}
          back={false}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            // history('/quotations');
          }}
        />
        <ListCustomers canAddNew={true} />
      </div>
    </div>
  );
});

export default Customers;
