import { useState } from 'react';
import styles from './searchBox.module.scss';
import { Icon } from './Icon';
import { useTranslation } from 'react-i18next';

interface SearchBoxProps {
  value: string;
  setValue: (newVal: string) => void;
  placeholder?: string;
  collapsed?: boolean;
}

export const SearchBox = ({ collapsed = false, ...props }: SearchBoxProps) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const { t } = useTranslation();

  return (
    <div>
      {isCollapsed && (
        <div
          className={styles.property1default}
          onClick={() => {
            setIsCollapsed(false);
          }}
        >
          <Icon icon={'search'} />
        </div>
      )}
      {!isCollapsed && (
        <div>
          <div className={styles.searchInput}>
            <div className={styles.left}>
              <div className={styles.searchBox}>
                <Icon icon={'search'} />
                <input
                  className={styles.inputField}
                  type="text"
                  placeholder={props.placeholder ?? t('common.search')}
                  value={props.value}
                  onChange={(event) => {
                    props.setValue(event.target?.value);
                  }}
                />
                {/* <div className={styles.rectangleParent}>
                <div className={styles.frameChild} />
                <div className={styles.recherche}>Recherche</div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
