import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import axios from 'axios';
import Config from '../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgEye } from '../../../assets/svg/eye.svg';
import TabFilter from '../TabFilter';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import Order, { GetOrder, GetOrderParameters } from '../../../models/Order';
import { Icon } from '../../../stories/dune/atoms/Icon';
import { isActiveList } from '../../../models';
import { ToastTypes } from '../../../models/ToastTypes';
import Switch from 'react-switch';
import useToast from '../../../hooks/use-toast';
import { GlobalParameter } from '../../../models/GlobalParameter';

interface ListOrdersProps {
  canAddNew: boolean;
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListOrders = React.memo((props: ListOrdersProps) => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('dateStart');
  const [sortDirection, setSortDirection] = useState('desc');
  //const [currentVehicle /*, setCurrentVehicle*/] = useState<Vehicle | undefined>();
  //const [isOpenUpdateVehicleWithdrawal, setIsOpenUpdateVehicleWithdrawal] = useState(false);
  const history = useNavigate();
  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>([]);

  const [filteredItems, setFilteredItems] = useState<Order[]>([]);
  const [isActive, setIsActive] = useState<isActiveList>({});

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/order/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.get_API_EXTRANET_URL(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilteredItems(res.data.content.items);
        setIsActive(
          res.data.content.items.reduce((acc: isActiveList, order: Order) => {
            acc[order.id ?? ''] = order.isActive ?? true;
            return acc;
          }, {}),
        );

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
      });
  };

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetOrderParameters = {};

    // if (tabFilters?.length > 0) toSend.status = tabFilters;

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        console.log(advancedFilters);
        if (x.filterType === 'search') toSend.search = x.filterData;
        if (x.filterType === 'buildingSite') toSend.buildingSiteId = x.filterData?.value;
        if (x.filterType === 'customer') toSend.customerId = x.filterData?.value;
        if (x.filterType === 'externalReference') toSend.externalReference = x.filterData;
        if (x.filterType === 'number') toSend.orderNumber = x.filterData;
        if (x.filterType === 'customerRef') toSend.customerOrderNumber = x.filterData;
        if (x.filterType === 'dateStart') toSend.dateStart = x.filterData;
        if (x.filterType === 'dateEnd') toSend.dateEnd = x.filterData;
        if (x.filterType === 'registrationPlate') toSend.registrationPlate = x.filterData;
      });
    }

    return toSend;
  }

  // useEffect(() => {
  //   fetchItems(1, perPage); // instead called by filtersHeader loading
  // }, []);

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  const seeDetails = (row: Order) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'orders';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.customerOrderNumber'),
      selector: (row: Order) => `${row.customerOrderNumber}`,
      cell: (row: Order) => (
        <Link to={seeDetails(row)}>
          <div className="firstColumn" data-tag="allowRowEvents">
            <div>
              <strong className="caption2medium" data-tag="allowRowEvents">
                {row.customerOrderNumber}
              </strong>
            </div>
            <div data-tag="allowRowEvents">
              <i className="caption2medium" data-tag="allowRowEvents">
                {row.externalReference ?? row.orderNumber}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'customerOrderNumber',
      grow: 1,
    },
    {
      name: t('common.deliveryPeriod'),
      selector: (row: Order) => row.dateEnd,
      cell: (row: Order) => (
        <div className="column" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">
            <div className="caption2medium" data-tag="allowRowEvents">
              {moment(row.dateStart).tz(siteTimeZone).format('DD/MM/YYYY') +
                ' - ' +
                (row.dateEnd ? moment(row.dateEnd).tz(siteTimeZone).format('DD/MM/YYYY') : t('common.inProgress'))}
            </div>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'dateStart',
    },
    {
      name: t('common.customer'),
      selector: (row: Order) => row.customer?.label,
      cell: (row: Order) => (
        <div className="firstColumn" data-tag="allowRowEvents">
          <strong className="caption2medium" data-tag="allowRowEvents">
            {row.customer?.label ?? '-'}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'customer',
      grow: 1,
    },
    {
      name: t('common.buildingSite'),
      selector: (row: Order) => row.buildingSite?.label,
      cell: (row: Order) => (
        <div className="firstColumn" data-tag="allowRowEvents">
          <strong className="caption2medium" data-tag="allowRowEvents">
            {row.buildingSite?.label ?? '-'}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'buildingSite',
      grow: 1,
    },
    // {
    //   name: t('common.active'),
    //   selector: (row: Order) => row.isActive,
    //   cell: (row: Order) => (
    //     <Switch onChange={onChangeSwitch} checked={isActive[row.id ?? ''] ?? false} id={row.id} onColor={'#2a85ff'} />
    //   ),
    //   sortable: true,
    //   right: false,
    //   id: 'isActive', // todo : remettre quand route fonctionnelle
    // },
  ];

  const onChangeSwitch = (checked: boolean, event: any, id: string) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/order/change-activation/' + id;

    axios
      .get(Config.get_API_EXTRANET_URL(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res: any) => {
        addToast(t('common.orderUpdated'), ToastTypes.success);
        setIsActive((prevState) => ({ ...prevState, [id]: checked }));
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={[
          'search',
          'number',
          'externalReference',
          'customerRef',
          'customer',
          'buildingSite',
          'dateStart',
          'dateEnd',
          'registrationPlate',
        ]}
        showAddNew={props.canAddNew}
        addNewText={t('common.newOrder')}
        addNew={() => {
          if (filtersAdvanced.find((x) => x.filterType === 'buildingSite')?.filterData !== undefined)
            history(
              '/orders/add/buildingSite/' +
                filtersAdvanced.find((x) => x.filterType === 'buildingSite')?.filterData?.value,
            );
          else if (filtersAdvanced.find((x) => x.filterType === 'customer')?.filterData !== undefined)
            history(
              '/orders/add/customer/' + filtersAdvanced.find((x) => x.filterType === 'customer')?.filterData?.value,
            );
          else history('/orders/add');
        }}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['search']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />

      <TabFilter
        tabArray={[
          {
            tabLabel: 'Tous',
            tabLabelTranslate: t('common.all'),
          },
        ]}
        parent={'Trip'}
        filterSet={() => {
          console.log('OK');
        }}
        defaultTab={'Tous'}
      />
      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className="noResults">{t('common.noOrder')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

class SearchStatus {
  status: string[] | undefined;
}

export default ListOrders;
