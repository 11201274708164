import AdditionalDoc from './AdditionalDoc';
import DefaultModel from './DefaultModel';
import { ProductType } from './ProductType';
import { VatRate } from './VatRate';
import Zone from './Zone';

export enum ProductUnit {
  TON = 'ton',
  M3 = 'm3',
  KG = 'kg',
  UNIT = 'unit',
  L = 'l',
  DELIVERY = 'delivery',
}

export enum TransportMode {
  EXWORKS = 'exworks',
  INCLUDED = 'included',
  DELIVERED = 'delivered',
}

export const TransportProductType = '07975c4a-22a3-41d0-9230-a6ec4f8d0d31'; // uuid du type de produit commun 'service transport'

export enum ProductTaxes {
  TGAP = 0.22,
}

export default class Product extends DefaultModel {
  label?: string;
  dataCustom?: string;
  unit?: string;
  weightByUnit?: number;
  taricCode?: string;
  externalReference?: string;
  multiProductPriority?: boolean;
  repCode?: string;
  repUnit?: string;
  repValue?: number;
  isSubjectToTgap?: boolean;
  lastImportAt?: Date;
  productTypeId?: string;
  productType?: ProductType;
  siteId?: string;
  affiliateId?: string;
  additionalDocs?: AdditionalDoc[];
  zones?: Zone[];
}

export interface GetProduct {
  id: string;
  label: string;
  externalReference?: string;
  unit?: ProductUnit;
  weightByUnit?: number;
  productType: string;
  productTypeId: string;
  taricCode?: string;
  multiProductPriority: boolean;
  repCode?: string;
  repUnit?: string;
  repValue?: number;
  isSubjectToTgap?: boolean;
  isActive: boolean;
  lastModificationAt?: Date;
  lastModificationBy?: string;
  createdAt?: Date;
  createdBy?: string;
  actualStockQuantity?: number;
  zones: Zone[];
  genericPrice?: number;
  vatRate: VatRate;
  dataCustom?: any;
}

export interface GetProductParameters {
  search?: string;
  externalReference?: string;
  label?: string;
  productTypeId?: string;
  isActive?: boolean;
}

export interface ProductSave {
  label?: string;
  multiProductPriority?: boolean;
  productTypeId?: string;
  unit?: ProductUnit;
  weightByUnit?: number;
  externalReference?: string;
  actualStockQuantity?: number;
  repCode?: string;
  repUnit?: string;
  repValue?: number;
  isSubjectToTgap?: boolean;
  dataCustom?: any;
  isActive?: boolean;
  taricCode?: string;
  genericPrice?: number;
  vatRateId?: string;
}
