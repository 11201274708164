import './toast.scss';

interface ToastProps {
  position: string;
  title: string;
  description: string;
  backgroundColor: string;
  onClick: () => void;
}

export const Toast = (props: ToastProps) => {
  return (
    <div
      className={`notification toast ${props.position}`}
      style={{ backgroundColor: props.backgroundColor }}
      onClick={props.onClick}
    >
      <p className="notification-title">{props.title}</p>
      <p className="notification-message">{props.description}</p>
    </div>
  );
};
