import React, { useEffect, useState } from 'react';
import { Title } from '../atoms/Title';
import Select, { components, InputProps, createFilter } from 'react-select';
import '../../../index.scss';
import './selectdata.scss';
import { customStylesSelectReactPopup } from '../../../styles/custom-styles-select-react';
import { useTranslation } from 'react-i18next';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import PopupContainer from '../organisms/PopupContainer';
import { eCommerce } from '@piwikpro/react-piwik-pro';

export interface SelectProps {
  setValue?: UseFormSetValue<any>;
  register?: UseFormRegister<any>;
  registerName: string;
  error: string;
  isSelectable: boolean;
  selectedOptionChanged?: (props: any) => void;
  clearOnSelect?: boolean;
  isPopup?: boolean;
  onClosePopup?: (selectedOption: any, linkedData?: any) => void;
  searchParent?: any | undefined;
  dependsOnSearchParent?: boolean;
  initialOption?: any;
  forceSelectedOption?: any;
  titleSize?: 'normal' | 'large' | 'small' | 'none';
  titleOverride?: string;
  noResultTextOverride?: string;
  hoverDescription?: string;
  placeholder?: string;
  forceOptions?: any[];
  inRow?: boolean;
  isClearable?: boolean;
  showParent?: boolean;
}
export interface SelectDataProps {
  title: string;
  registerName: string;
  titleSize: 'normal' | 'large' | 'small' | 'none';
  hoverDescription?: string;
  setValue?: UseFormSetValue<any>;
  register?: UseFormRegister<any>;
  error: string;
  isSelectable: boolean;
  selectedOptionChanged?: (props: any) => void;
  clearOnSelect?: boolean;
  searchParent?: any | undefined;
  dependsOnSearchParent?: boolean;
  initialOption?: any;
  forceSelectedOption?: any;
  isLoading: boolean;
  options: any[];
  filterText: string;
  onClickNoResult?: () => void;
  noResultText?: string;
  placeholder: string;
  isPopup?: boolean;
  onClosePopup?: (selectedOption: any, linkedData?: any) => void;
  fetch?: (filterText: string) => void;
  fetchAtLoad?: boolean;
  styledOptions?: StyledOption[];
  isSearchable?: boolean;
  isClearable?: boolean;
  inRow?: boolean;
}
export interface SelectDataOption {
  value: string;
  label: string;
  searchParentRef?: any;
  data?: any;
}

export interface StyledOption {
  optionValues: string[];
  styleColor: string;
}

export const SelectData = (props: SelectDataProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<SelectDataOption | null>(props.initialOption);

  const [errorMessage, setErrorMessage] = useState('');
  const [searchedParent, setSearchedParent] = useState<string | undefined>();

  useEffect(() => {
    if (props.selectedOptionChanged !== undefined && selectedOptions !== undefined) {
      props.selectedOptionChanged(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (
      props.forceSelectedOption !== undefined &&
      props.forceSelectedOption !== props.initialOption &&
      props.forceSelectedOption !== selectedOptions
    )
      update(props.forceSelectedOption);
  }, [props.forceSelectedOption]);

  useEffect(() => {
    if (props.searchParent !== searchedParent) {
      setSearchedParent(props.searchParent);

      if (props.fetchAtLoad && props.fetch) {
        props.fetch(query);
      }
    }
    if (
      (props.searchParent === undefined && (props.dependsOnSearchParent || searchedParent !== undefined)) ||
      (props.searchParent !== searchedParent &&
        searchedParent !== undefined &&
        searchedParent.length > 0 &&
        !Array.isArray(searchedParent)) ||
      (searchedParent !== undefined &&
        searchedParent.length > 0 &&
        Array.isArray(searchedParent) &&
        !arraysAreEqual(props.searchParent, searchedParent))
    ) {
      // si on a vidé le parent OU si on avait un parent et que le parent a changé
      deleteSelectedOption();
    }
  }, [props.searchParent, searchedParent]);

  const update = (newVal: any) => {
    if (props.setValue) props.setValue(props.registerName, newVal);
    setSelectedOptions(newVal);
    setErrorMessage('');
  };

  const deleteSelectedOption = () => {
    setSelectedOptions(null);
    if (props.setValue) props.setValue(props.registerName, { value: null, label: null });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => (query.length > 0 ? (props.fetch ? props.fetch(query) : null) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    setErrorMessage(props.error);
  }, [props.error]);

  useEffect(() => {
    if (props.fetchAtLoad && props.fetch) {
      props.fetch(query);
    }
  }, []);

  function handleChange(input: any) {
    if (input != null && input.value != null) {
      update(input);
      if (props.clearOnSelect) {
        const timeOutId = setTimeout(() => setSelectedOptions(null), 50);
        return () => clearTimeout(timeOutId);
      }
    } else {
      deleteSelectedOption();
    }
  }

  function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };
  const { onBlur, name, ref } = props.register
    ? props.register(props.registerName, {})
    : { onBlur: undefined, name: props.title, ref: undefined };

  function getTitleType() {
    if (props.titleSize === 'large') return 'title1';
    else if (props.titleSize === 'small') return 'h5';
    else return 'label';
  }

  function getSelectCustomStyle(value: string | undefined) {
    const color = getCustomColor(value);

    if (props.styledOptions) {
      return {
        control: (base: any) => ({
          ...base,
          background: color,
          width: '100%',
          // Overwrites the different states of border
          borderColor: color,
        }),
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 100,
        }),
        singleValue: (base: any) => ({
          ...base,
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        }),
      };
    } else {
      return customStylesSelectReactPopup('#F4F4F4', props.inRow);
    }
  }

  function getCustomColor(value: string | undefined) {
    let color = '#FCFCFC';

    if (props.styledOptions && value) {
      for (const x of props.styledOptions) {
        if (x.optionValues.findIndex((y) => y === value) !== -1) {
          color = x.styleColor;
          break;
        }
      }
    }
    return color;
  }

  const [menuOpen, setMenuOpen] = useState(false);

  const content = (
    <div className="select-data" title={props.hoverDescription}>
      {props.title && props.titleSize !== 'none' && <Title type={getTitleType()} align="left" label={props.title} />}
      <div className="panelInput" data-tag="allowRowEvents">
        {props.isSelectable ? (
          <Select
            name={name}
            ref={ref}
            menuIsOpen={menuOpen}
            onChange={handleChange}
            onBlur={(e) => {
              setMenuOpen(false);
              if (onBlur) onBlur(e);
            }}
            blurInputOnSelect={true}
            onFocus={() => setMenuOpen(true)}
            className="select-data base2"
            isMulti={undefined}
            styles={getSelectCustomStyle(selectedOptions?.value)}
            isLoading={props.isLoading}
            options={props.options}
            loadingMessage={() => {
              t('common.loading');
            }}
            noOptionsMessage={() => {
              if (props.filterText.length < 1) {
                return (
                  <>
                    <div>{t('common.searchWith1Caracter')}</div>
                    {props.onClickNoResult && (
                      <div>
                        <a
                          className="link base2"
                          onClick={() => {
                            setMenuOpen(false);
                            if (props.onClickNoResult) props.onClickNoResult();
                          }}
                        >
                          {props.noResultText ? props.noResultText : t('common.new') + ' ' + props.title}
                        </a>
                      </div>
                    )}
                  </>
                );
              } else {
                return (
                  <>
                    <div>{t('common.noResult')}</div>
                    {props.onClickNoResult && (
                      <div>
                        <a
                          className="link base2"
                          onClick={() => {
                            setMenuOpen(false);
                            if (props.onClickNoResult) props.onClickNoResult();
                          }}
                        >
                          {props.noResultText ? props.noResultText : t('common.new') + ' ' + props.title}
                        </a>
                      </div>
                    )}
                  </>
                );
              }
            }}
            placeholder={props.placeholder}
            onInputChange={(e) => setQuery(e)}
            isSearchable={props.isSearchable ?? true}
            value={selectedOptions ?? null}
            filterOption={createFilter(filterConfig)}
            isClearable={props.isClearable ?? true}
            autoFocus={props.isPopup}
            menuPlacement={'auto'}
            menuPosition={'absolute'}
            menuPortalTarget={document.body}
            components={
              props.inRow
                ? {
                    IndicatorSeparator: () => null,
                    // Input: (props: InputProps<SelectDataOption, true>) => <components.Input {...props} />,
                    //   console.log('inputprops :', props);
                    //   return (
                    //     <components.Input
                    //       {...props}
                    //       inputClassName="inputField"
                    //       onChange={(e: any) => {
                    //         console.log('inputchange :', e);
                    //         setQuery(e);
                    //       }}
                    //     />
                    //   );
                    // },
                  }
                : undefined
            }
          />
        ) : (
          <div className="base2 badge" style={{ backgroundColor: getCustomColor(selectedOptions?.value) }}>
            {selectedOptions?.label}
          </div>
        )}
        <p className="validator-error">{errorMessage}</p>
      </div>
    </div>
  );

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          onConfirm={function (): void {
            if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
            else props.onClosePopup(selectedOptions);
          }}
          onCancel={function (): void {
            if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
            else props.onClosePopup(undefined);
          }}
        >
          {content}
        </PopupContainer>
      ) : (
        content
      )}
    </>
  );
};
