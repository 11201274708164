import React, { useEffect, useState } from 'react';
import { Language } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import Config from '../../../Config';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import { GetQuotation, GetQuotationHistory } from '../../../models/Quotation';
import moment from 'moment';
import { SelectStatus } from '../../forms/SelectStatus';
import { GlobalParameter } from '../../../models/GlobalParameter';
interface QuotationHistoriesProps {
  quotationData: GetQuotation;
}

const QuotationHistories = React.memo((props: QuotationHistoriesProps) => {
  // #region const init

  // #region generic
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem('language') ?? Language.FR;
  const { addToast, addFixedToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('asc');

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific
  const [filteredItems, setFilteredItems] = useState<GetQuotationHistory[]>([]);

  const fetchItems = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/quotation/' + props.quotationData.id + '/quotation-histories/get';

    setIsLoading(true);
    setFilteredItems([]);

    axios
      .get(Config.get_API_EXTRANET_URL(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setFilteredItems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const columns: any[] = [
    {
      name: t('common.date'),
      selector: (row: GetQuotationHistory) => row.createdAt,
      cell: (row: GetQuotationHistory) => (
        <div className="column">
          <div>
            <div className="caption2medium">{moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}</div>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'createdAt',
    },
    {
      name: t('common.status'),
      selector: (row: GetQuotationHistory) => row.status,
      cell: (row: GetQuotationHistory) => (
        <div className="column">
          <SelectStatus
            registerName={''}
            error={''}
            titleSize="none"
            forceSelectedOption={row.status}
            statusType={'quotation'}
            isSelectable={false}
          />
        </div>
      ),
      sortable: false,
      right: false,
      id: 'status',
    },
    {
      name: t('common.updatedBy'),
      selector: (row: GetQuotationHistory) => row.createdBy,
      cell: (row: GetQuotationHistory) => (
        <div className="column">
          <div>
            <strong className="caption2medium" data-tag="allowRowEvents">
              {row.createdBy}
            </strong>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'createdBy',
    },
    {
      name: t('common.comment'),
      selector: (row: GetQuotationHistory) => row.comment,
      cell: (row: GetQuotationHistory) => (
        <div className="column">
          <div>
            <strong className="caption2medium" data-tag="allowRowEvents">
              {row.comment}
            </strong>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'comment',
    },
  ];

  // #endregion specific

  // #endregion const init

  // #region event handling

  useEffect(() => {
    fetchItems();
  }, []);

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems();
  };

  // #endregion event handling

  // TODO DESIGN : revoir l'espacement quand pas de filtre ?

  return (
    <>
      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <>
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className="noResults">{t('common.noResult')}</div>}
            defaultSortAsc={sortDirection == 'asc'}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
          />
        </>
      )}
    </>
  );
});

export default QuotationHistories;
