import { SelectData, SelectProps, SelectDataOption } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import { GetLocality } from '../../models/GeoGouv';
import stringUtils from '../../utils/stringUtils';

export interface SelectLocalityOption extends SelectDataOption {
  data: GetLocality;
}

export const SelectLocality = ({
  setValue,
  register,
  registerName = 'order',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = true,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectLocalityOption[]>([]);
  const [filterText, setFilterText] = useState('');

  const { t } = useTranslation();

  const fetchData = async (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoading(true);

      const baseUrl = 'https://geo.api.gouv.fr/communes';
      const url = `${baseUrl}?nom=${filterText}&fields=nom,code,codesPostaux`;

      let fetched: GetLocality[] = [];
      try {
        const res = await axios.get(url);
        setIsLoading(false);
        fetched = res.data;
      } catch (e) {
        setIsLoading(false);
        setFetchedOptions([]);
      }

      if (
        !fetched ||
        fetched.length === 0 ||
        !fetched.find((x: any) => x.nom.toLowerCase() === filterText.toLowerCase())
      ) {
        fetched.push({ nom: filterText, code: '', codesPostaux: ['INCONNU'] });
      }
      setFetchedOptions(fetched.sort((a, b) => (a.nom > b.nom ? 1 : -1)));
    } else {
      setFetchedOptions([]);
    }
  };

  const setFetchedOptions = (fetchedOptions: GetLocality[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.code,
          label: s.nom + (s.codesPostaux && s.codesPostaux.length > 0 ? ' - ' + s.codesPostaux[0] : ''),
          data: s,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  return (
    <SelectData
      title={t('common.locality')}
      placeholder={t('common.localitySearch')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption}
      filterText={filterText}
      fetch={fetchData}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};
