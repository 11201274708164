import React, { useEffect, useState } from 'react';
//import { ReactComponent as SvgEye } from '../assets/svg/eye.svg';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import { useNavigate } from 'react-router-dom';
import ListVehicles from '../../lists/Vehicles/ListVehicles';

const Vehicles = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div className="main-container">
      <Menu selectedPage="vehicles" />
      <div className="right detail-container">
        <Head
          title={t('common.vehicles')}
          back={false}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            // history('/quotations');
          }}
        />
        <ListVehicles canAddNew={true} />
      </div>
    </div>
  );
});

export default Vehicles;
